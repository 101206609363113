import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import CryptoJS from 'crypto-js';

const secretPass = 'XkhZG4fW2t2W';

export const numberWithCommas = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const reformatString = string => {
  return string
    .replaceAll('_', ' ')
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

export const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const fileExtension = '.xlsx';

export const exportToCSV = (apiData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const encryptData = payload => {
  return CryptoJS.AES.encrypt(JSON.stringify(payload), secretPass).toString();
};

export const decryptData = payload => {
  const bytes = CryptoJS.AES.decrypt(payload, secretPass);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
