import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Input } from 'antd';
import moment from 'moment/moment';

import { NavigationBar } from '../../Components';
import servicesRequest from '../../Utils/serviceRequest.utils';
import { USER_URL } from '../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import { TitleScreen, TitleSection } from '../Blog/Blog.styled';
import UserTableComponent from './Components/UserTable.component';
import { exportToCSV } from '../../Utils/general.utils';
import { DisplayFlex } from '../../Assets/GlobalStyled';

const UserContainer = () => {
  const [users, setUsers] = React.useState([]);
  const [members, setMembers] = React.useState([]);
  const [search, setSearch] = React.useState('');

  const dispatch = useDispatch();

  const fileName = 'Member';

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const reformatOrders = users.map(item => ({
    Code: item?.id,
    Name: item?.name,
    Email: item?.email,
    phone: item?.phone,
    Registered: moment(item?.createdAt).format('YYYY-MM-DD'),
    Membership: item?.membershipType,
    status: item?.status === true ? 'Active' : 'Not Active'
  }));

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(USER_URL, {
        params: {
          search
        }
      });

      setUsers(data);
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  const fetchingMemberList = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(USER_URL + '/member');

      const reformatData = () =>
        data.map(item => {
          return {
            value: item?.member_type,
            label: item?.member_descript
          };
        });

      setMembers(reformatData());
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  const handleUpdateStatus = async (code, type) => {
    try {
      const request = await servicesRequest();
      await request.patch(USER_URL + '/member', { code, type });
      await fetching();
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  React.useEffect(() => {
    fetching().catch(console.error);
    fetchingMemberList().catch(console.error);
  }, []);

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetching().catch(console.error);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <NavigationBar>
      <TitleSection>
        <TitleScreen>Manage Member</TitleScreen>
        <DisplayFlex style={{ gap: '2px' }}>
          <Input
            placeholder="Search member code"
            onChange={e => {
              setSearch(e.target.value);
            }}
          />
          <Button onClick={() => exportToCSV(reformatOrders, fileName)}>
            Export File
          </Button>
        </DisplayFlex>
      </TitleSection>
      <hr />
      <UserTableComponent
        users={users}
        members={members}
        handleUpdateStatus={handleUpdateStatus}
      />
    </NavigationBar>
  );
};

export default UserContainer;
