import React from 'react';
import { Button, Modal } from 'antd';
import { Col, Form } from 'react-bootstrap';

import { InputContainer, LabelForm, TextInput } from '../../Blog/Blog.styled';
import { FORM_TYPE } from '../../../Constants';

const defaultFormat = {
  name: '',
  type: FORM_TYPE.PARAGRAPH,
  isRequired: true,
  option: ''
};

const COL = 3;

const EventModal = ({ show, handleClose, onAddForm }) => {
  const [formObject, setFormObject] = React.useState(defaultFormat);

  const onOk = () => {
    onAddForm(formObject);
    setFormObject(defaultFormat);
  };

  return (
    <Modal
      title="Add Form"
      open={show}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onOk}
          disabled={formObject.name === ''}>
          Submit
        </Button>
      ]}>
      <hr />
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Type</LabelForm>
        </Col>
        <Col>
          <Form.Check
            checked={formObject.type === FORM_TYPE.PARAGRAPH}
            inline
            label="Short Paragraph"
            type="radio"
            id={`inline-radio-1`}
            onChange={() =>
              setFormObject({ ...formObject, type: FORM_TYPE.PARAGRAPH })
            }
          />
          <Form.Check
            checked={formObject.type === FORM_TYPE.MULTIPLE}
            inline
            label="Multiple Choice"
            type="radio"
            id={`inline-radio-1`}
            onChange={() =>
              setFormObject({ ...formObject, type: FORM_TYPE.MULTIPLE })
            }
          />
        </Col>
      </InputContainer>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Name</LabelForm>
        </Col>
        <Col>
          <TextInput
            value={formObject.name}
            onChange={event =>
              setFormObject({ ...formObject, name: event.target.value })
            }
            placeholder="Form Name"
          />
        </Col>
      </InputContainer>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Required</LabelForm>
        </Col>
        <Col>
          <Form.Check
            checked={formObject.isRequired}
            inline
            label="True"
            type="radio"
            id={`inline-radio-1`}
            onChange={() => setFormObject({ ...formObject, isRequired: true })}
          />
          <Form.Check
            checked={!formObject.isRequired}
            inline
            label="False"
            type="radio"
            id={`inline-radio-1`}
            onChange={() => setFormObject({ ...formObject, isRequired: false })}
          />
        </Col>
      </InputContainer>
      {formObject.type === FORM_TYPE.MULTIPLE && (
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>Options</LabelForm>
          </Col>
          <Col>
            <TextInput
              value={formObject.option}
              onChange={event =>
                setFormObject({ ...formObject, option: event.target.value })
              }
              placeholder="Options"
            />
            <span>
              <i>
                Please enter options and separate them with a comma (,). (i.e:
                food, drink)
              </i>
            </span>
          </Col>
        </InputContainer>
      )}
    </Modal>
  );
};

export default EventModal;
