import { notification } from 'antd';

import { SHOW_NOTIFICATION } from '../Actions/action.types';

const notificationReducer = (state = {}, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return notification.open({
        message: action.payload.title || 'Notification',
        description: action.payload.message || 'Notification description',
        onClick: () => {}
      });
    default:
      return state;
  }
};

export default notificationReducer;
