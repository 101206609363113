import React from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  Container,
  LogoTitle,
  MenuCard,
  MenuLabel,
  NavContainer
} from './Home.styled';
import { Button, StyledLink } from '../../Assets/GlobalStyled';

const NavigationHead = () => {
  return (
    <NavContainer>
      <div>
        <LogoTitle>WineBuff</LogoTitle>
      </div>
      <Button>Logout</Button>
    </NavContainer>
  );
};

const HomeScreen = () => {
  return (
    <Container>
      <NavigationHead />
      <div>
        <Row className="gy-4 mt-1">
          <Col md={2}>
            <StyledLink to="/menu/footer">
              <MenuCard>
                <MenuLabel>Menu</MenuLabel>
              </MenuCard>
            </StyledLink>
          </Col>
          <Col md={2}>
            <StyledLink to="/menu">
              <MenuCard>
                <MenuLabel>Content</MenuLabel>
              </MenuCard>
            </StyledLink>
          </Col>
          <Col md={2}>
            <StyledLink to="/menu">
              <MenuCard>
                <MenuLabel>Admin Settings</MenuLabel>
              </MenuCard>
            </StyledLink>
          </Col>
          <Col md={2}>
            <StyledLink to="/menu">
              <MenuCard>
                <MenuLabel>Payment Settings</MenuLabel>
              </MenuCard>
            </StyledLink>
          </Col>
          <Col md={2}>
            <StyledLink to="/menu">
              <MenuCard>
                <MenuLabel>Event Listings</MenuLabel>
              </MenuCard>
            </StyledLink>
          </Col>
          <Col md={2}>
            <StyledLink to="/menu">
              <MenuCard>
                <MenuLabel>Product Listings</MenuLabel>
              </MenuCard>
            </StyledLink>
          </Col>
          <Col md={2}>
            <StyledLink to="/menu">
              <MenuCard>
                <MenuLabel>Members Management</MenuLabel>
              </MenuCard>
            </StyledLink>
          </Col>
          <Col md={2}>
            <StyledLink to="/menu">
              <MenuCard>
                <MenuLabel>Orders Management</MenuLabel>
              </MenuCard>
            </StyledLink>
          </Col>
          <Col md={2}>
            <StyledLink to="/menu">
              <MenuCard>
                <MenuLabel>Email Marketing</MenuLabel>
              </MenuCard>
            </StyledLink>
          </Col>
          <Col md={2}>
            <StyledLink to="/menu">
              <MenuCard>
                <MenuLabel>Shipment Management</MenuLabel>
              </MenuCard>
            </StyledLink>
          </Col>
          <Col md={2}>
            <StyledLink to="/menu">
              <MenuCard>
                <MenuLabel>Discounts Management</MenuLabel>
              </MenuCard>
            </StyledLink>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default HomeScreen;
