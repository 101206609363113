import React from 'react';
import { Button, Table } from 'antd';

const PaymentTableComponent = ({ payments, setUpdateModalActive, update }) => {
  const mapping = () => {
    return payments.map(payment => {
      return {
        ...payment,
        key: payment.id
      };
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.title - b.title
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   render: (_, record) => <>{record?.status ? 'Active' : 'Nonactive'}</>
    // },
    {
      title: 'Action',
      key: 'editPage',
      align: 'center',
      render: (_, record) => (
        <>
          <Button onClick={() => setUpdateModalActive(record)}>Update</Button>
          <Button
            danger={record?.status}
            className="mx-1"
            onClick={() => handleUpdateStatus(record)}>
            {record?.status ? 'Deactivate' : 'Activate'}
          </Button>
        </>
      )
    }
  ];

  const handleUpdateStatus = async data => {
    let { name, description, id, status } = data;
    status = !status;

    await update({ name, description, paymentMethodId: id, status });
  };

  return (
    <Table
      size="small"
      pagination={false}
      columns={columns}
      dataSource={mapping()}
    />
  );
};

export default PaymentTableComponent;
