import React from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';

import { TitleScreen, TitleSection } from '../Blog/Blog.styled';
import { NavigationBar, Pagination } from '../../Components';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import servicesRequest from '../../Utils/serviceRequest.utils';
import { BURGUNDY_URL } from '../../Utils/serviceUrl.utils';
import BurgundyTableComponent from './Components/BurgundyTable.component';

const BurgundyContainer = () => {
  const [burgundies, setBurgundies] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [search, setSearch] = React.useState('');

  const pageSize = 10;

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: {
          data: { totalItems, contents }
        }
      } = await request.get(BURGUNDY_URL, {
        params: {
          page: pages,
          size: pageSize,
          search
        }
      });

      setTotalItems(totalItems);
      setBurgundies(contents);
    } catch (error) {
      showNotification(
        'Error while trying fetching burgundy list. Please try again'
      );
    }
  };

  const deleteMap = async id => {
    try {
      const request = await servicesRequest();
      await request.delete(`${BURGUNDY_URL}/${id}`);

      fetching().catch(console.error);
      showNotification('Burgundy map successfully deleted');
    } catch (error) {
      showNotification(
        'Error while trying deleting burgundy. Please try again'
      );
    }
  };

  const deleteSubMap = async id => {
    try {
      const request = await servicesRequest();
      await request.delete(`${BURGUNDY_URL}/sub/${id}`);

      fetching().catch(console.error);
      showNotification('Sub burgundy map successfully deleted');
    } catch (error) {
      showNotification(
        'Error while trying deleting burgundy. Please try again'
      );
    }
  };

  const paginationProps = () => ({
    data: { total_page: totalItems, pageSize },
    onChangePage: page => {
      setPages(page - 1);
    }
  });

  React.useEffect(() => {
    fetching().catch(console.error);
  }, [pages]);

  return (
    <NavigationBar>
      <TitleSection>
        <TitleScreen>Burgundy Map</TitleScreen>
        <Button href="/burgundy/add">Add </Button>
      </TitleSection>
      <hr />
      <BurgundyTableComponent
        onDelete={id => deleteMap(id)}
        onSubDelete={id => deleteSubMap(id)}
        burgundies={burgundies}
      />
      <Pagination {...paginationProps()} />
    </NavigationBar>
  );
};

export default BurgundyContainer;
