import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { NavigationBar } from '../../../Components';
import {
  ButtonContainer,
  InputContainer,
  LabelForm,
  TextInput,
  TitleScreen,
  TitleSection
} from '../../Blog/Blog.styled';
import { CancelButton, SaveButton } from '../../Menu/Menu.styled';
import { useNavigate } from 'react-router-dom';
import servicesRequest from '../../../Utils/serviceRequest.utils';
import { DISCOUNT_URL } from '../../../Utils/serviceUrl.utils';
import ROUTE from '../../../Constants/route.constant';
import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';
import {
  DISCOUNT_CRITERIA,
  DISCOUNT_METHOD,
  DISCOUNT_TYPE
} from '../../../Constants';
import { Button } from 'antd';
import SelectProductComponent from './SelectProduct.component';

const type = 'radio';
const COL = 3;

const AddDiscountComponent = () => {
  const [enablePeriodValidation, setEnablePeriodValidation] =
    React.useState(false);
  const [limitation, setLimitation] = React.useState(false);
  const [discountMethod, setDiscountMethod] = React.useState(
    DISCOUNT_METHOD.AUTO
  );
  const [discountCriteria, setDiscountCriteria] = React.useState(
    DISCOUNT_CRITERIA.NO_CRITERIA
  );
  const [modalSelectProduct, setModalSelectProduct] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState([]);

  React.useEffect(() => {
    if (
      discountCriteria === DISCOUNT_CRITERIA.NO_CRITERIA &&
      discountMethod === DISCOUNT_METHOD.CODE
    ) {
      setDiscountCriteria(DISCOUNT_CRITERIA.MIN_ORDER_AMOUNT);
    }
  }, [discountMethod]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      method: DISCOUNT_METHOD.AUTO,
      discountCode: '',
      name: '',
      applyTo: [],
      type: DISCOUNT_TYPE.FIXED,
      value: 0,
      discountCriteria: DISCOUNT_CRITERIA.NO_CRITERIA,
      discountCriteriaValue: 0,
      validationPeriod: enablePeriodValidation,
      validationPeriodDateStart: '',
      validationPeriodDateEnd: '',
      limitation: limitation,
      limitationValue: 0
    }
  });

  React.useEffect(() => {
    setValue('applyTo', selectedProduct);
  }, [selectedProduct]);

  const onSubmit = async data => {
    const payload = {
      ...data,
      applyTo: discountMethod === DISCOUNT_METHOD.CODE ? [] : selectedProduct
    };

    try {
      const request = await servicesRequest();
      await request.post(DISCOUNT_URL, payload);
      showNotification('Discount successfully added');

      setTimeout(() => {
        navigate(`/${ROUTE.discount}`);
      }, 2000);
    } catch (error) {
      showNotification('Failed to add');
    }
  };

  return (
    <NavigationBar>
      <TitleSection>
        <TitleScreen>Add New Discount</TitleScreen>
      </TitleSection>
      <hr />

      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Method</LabelForm>
        </Col>
        <Col>
          <Controller
            name="method"
            control={control}
            render={({ field: { onChange, value } }) => (
              <React.Fragment>
                <Form.Check
                  checked={value === DISCOUNT_METHOD.AUTO}
                  inline
                  label="Auto Discount"
                  type={type}
                  id={`inline-${type}-1`}
                  onChange={() => {
                    onChange(DISCOUNT_METHOD.AUTO);
                    setDiscountMethod(DISCOUNT_METHOD.AUTO);
                  }}
                />
                <Form.Check
                  checked={value === DISCOUNT_METHOD.CODE}
                  inline
                  label="Discount Code"
                  type={type}
                  id={`inline-${type}-2`}
                  onChange={() => {
                    onChange(DISCOUNT_METHOD.CODE);
                    setDiscountMethod(DISCOUNT_METHOD.CODE);
                  }}
                />
              </React.Fragment>
            )}
          />
        </Col>
      </InputContainer>
      <div hidden={discountMethod === DISCOUNT_METHOD.AUTO}>
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>Discount Code</LabelForm>
          </Col>
          <Col>
            <Controller
              name="discountCode"
              control={control}
              rules={{
                required: discountMethod === DISCOUNT_METHOD.CODE
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  readOnly={discountMethod === DISCOUNT_METHOD.AUTO}
                  value={value}
                  onChange={onChange}
                  placeholder="Discount Code"
                  error={errors?.discountCode}
                />
              )}
            />
          </Col>
        </InputContainer>
      </div>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Discount Name</LabelForm>
        </Col>
        <Col>
          <Controller
            name="name"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="Discount Name"
                error={errors?.name}
              />
            )}
          />
        </Col>
      </InputContainer>
      {discountMethod === DISCOUNT_METHOD.AUTO && (
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>Apply To</LabelForm>
          </Col>
          <Col>
            <Button onClick={() => setModalSelectProduct(true)}>
              {selectedProduct?.length > 0
                ? selectedProduct?.toString()
                : 'Click to select product'}
            </Button>
          </Col>
        </InputContainer>
      )}
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Type</LabelForm>
        </Col>
        <Col>
          <Row>
            <Col>
              <Controller
                name="type"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Form.Select
                    defaultChecked={value}
                    onChange={e => onChange(e.target.value)}>
                    <option disabled>Select Discount Type</option>
                    <option value={DISCOUNT_TYPE.FIXED}>Fixed</option>
                    <option value={DISCOUNT_TYPE.PERCENTAGE}>Percentage</option>
                  </Form.Select>
                )}
              />
            </Col>
            <Col>
              <Controller
                name="value"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    value={value}
                    onChange={onChange}
                    placeholder="Value"
                    error={errors?.value}
                  />
                )}
              />
            </Col>
          </Row>
        </Col>
      </InputContainer>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Discount Criteria</LabelForm>
        </Col>
        <Col>
          <Row>
            <Col>
              <Controller
                name="discountCriteria"
                control={control}
                render={({ field: { onChange } }) => (
                  <Form.Select
                    aria-label="DiscountCriteria"
                    onChange={e => {
                      setDiscountCriteria(e.target.value);
                      onChange(e.target.value);
                    }}>
                    {discountMethod === DISCOUNT_METHOD.AUTO && (
                      <option value={DISCOUNT_CRITERIA.NO_CRITERIA}>
                        No Criteria
                      </option>
                    )}
                    <option value={DISCOUNT_CRITERIA.MIN_PURCHASE_AMOUNT}>
                      Minimum Purchase Amount
                    </option>
                    <option value={DISCOUNT_CRITERIA.MIN_ORDER_AMOUNT}>
                      Minimum Order Amount
                    </option>
                  </Form.Select>
                )}
              />
            </Col>

            <Col>
              {discountCriteria !== DISCOUNT_CRITERIA.NO_CRITERIA && (
                <Controller
                  name="discountCriteriaValue"
                  control={control}
                  rules={{
                    required: discountCriteria !== DISCOUNT_CRITERIA.NO_CRITERIA
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      placeholder={
                        discountCriteria === DISCOUNT_CRITERIA.MIN_ORDER_AMOUNT
                          ? 'Minimum Order Amount'
                          : 'Minimum Purchase Amount'
                      }
                      error={errors?.discountCriteriaValue}
                      onChange={onChange}
                    />
                  )}
                />
              )}
            </Col>
          </Row>
        </Col>
      </InputContainer>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Enable Validation Period</LabelForm>
        </Col>
        <Col>
          <Controller
            name="validationPeriod"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Form.Check
                type="checkbox"
                onChange={() => {
                  onChange(!enablePeriodValidation);
                  setEnablePeriodValidation(!enablePeriodValidation);
                }}
                defaultChecked={value}
              />
            )}
          />
        </Col>
      </InputContainer>
      {enablePeriodValidation && (
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>Validation Date Period</LabelForm>
          </Col>
          <Col>
            <Row>
              <Col>
                <Controller
                  name="validationPeriodDateStart"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      type="date"
                      value={value}
                      onChange={onChange}
                      error={errors?.validationPeriodDateStart}
                    />
                  )}
                />
              </Col>
              <Col>
                <Controller
                  name="validationPeriodDateEnd"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      type="date"
                      value={value}
                      onChange={onChange}
                      error={errors?.title}
                    />
                  )}
                />
              </Col>
            </Row>
          </Col>
        </InputContainer>
      )}
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Limitation</LabelForm>
        </Col>
        <Col>
          <Controller
            name="limitation"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Form.Check
                type="checkbox"
                onChange={() => {
                  onChange(!limitation);
                  setLimitation(!limitation);
                }}
                defaultChecked={value}
              />
            )}
          />
        </Col>
      </InputContainer>
      {limitation && (
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>Number of Limitation</LabelForm>
          </Col>
          <Col>
            <Controller
              name="limitationValue"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  type="number"
                  value={value}
                  onChange={onChange}
                  placeholder="Limitation Value"
                  error={errors?.limitationValue}
                />
              )}
            />
          </Col>
        </InputContainer>
      )}

      <SelectProductComponent
        modalSelectProduct={modalSelectProduct}
        setModalSelectProduct={setModalSelectProduct}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
      />

      <ButtonContainer>
        <CancelButton onClick={() => navigate(-1)}>Cancel</CancelButton>
        <SaveButton onClick={handleSubmit(onSubmit)}>Save</SaveButton>
      </ButtonContainer>
    </NavigationBar>
  );
};

export default AddDiscountComponent;
