import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button } from 'antd';
import moment from 'moment';

import { TitleScreen, TitleSection } from '../../Blog/Blog.styled';
import { reformatString } from '../../../Utils/general.utils';
import { ColTitle, DetailContainer, Table } from '../OrderDetail.styled';

const DetailOrderComponent = props => {
  let { data, setDetailData } = props;

  let {
    address: {
      billingDetails,
      shippingDetails,
      shipmentMethod,
      remarks,
      deliveryStatus
    },
    id,
    payments: {
      paymentMethod,
      paymentStatus,
      cardType = '',
      paymentTraceId = ''
    },
    createdAt,
    purchase_products: products,
    deliveryFee,
    totalFinal
  } = data;

  createdAt = moment(createdAt).format('YYYY-MM-DD');
  paymentStatus = reformatString(paymentStatus);
  deliveryStatus = reformatString(deliveryStatus);
  billingDetails = JSON.parse(billingDetails);
  shippingDetails = JSON.parse(shippingDetails);

  const _renderComponentRow = (label, value) => {
    return (
      <Col md={12}>
        <Row>
          <Col md={4}>
            <b>{label}</b>
          </Col>
          <Col md={8}>{value}</Col>
        </Row>
      </Col>
    );
  };

  return (
    <React.Fragment>
      <TitleSection>
        <TitleScreen>Detail Order</TitleScreen>
        <Button
          onClick={() => {
            setDetailData(null);
          }}>
          Back
        </Button>
      </TitleSection>
      <hr />

      <div className="mt-5">
        <Row className="gy-3">
          {_renderComponentRow('Order Number', id)}
          {_renderComponentRow('Order Date', createdAt)}
          {_renderComponentRow('Payment Method', reformatString(paymentMethod))}
          {_renderComponentRow('Card Type', cardType)}
          {_renderComponentRow('Transaction Number', paymentTraceId)}
          {_renderComponentRow('Payment Status', paymentStatus)}
          {_renderComponentRow('Delivery Method', shipmentMethod)}
          {_renderComponentRow('Delivery Status', deliveryStatus)}
          {_renderComponentRow('Customer Remark', remarks)}
        </Row>
        <DetailContainer className="mt-4">
          <Row>
            <Col md={6}>
              <ColTitle className="p-3">Bill To Information</ColTitle>
              <div className="m-3">
                <p className="m-0 lh-4">{billingDetails?.name}</p>
                <p className="m-0 lh-4">{billingDetails?.email}</p>
                <p className="m-0 lh-4">{billingDetails?.phoneNumber}</p>
                <p className="m-0 lh-4">
                  {billingDetails?.addressLine}, {billingDetails?.district}
                </p>
              </div>
            </Col>
            <Col md={6}>
              <ColTitle className="p-3">Shipment Address</ColTitle>
              <div className="m-3">
                <p className="m-0 lh-4">{shippingDetails?.name}</p>
                <p className="m-0 lh-4">{shippingDetails?.email}</p>
                <p className="m-0 lh-4">{shippingDetails?.phoneNumber}</p>
                <p className="m-0 lh-4">
                  {shippingDetails?.addressLine}, {shippingDetails?.district}
                </p>
              </div>
            </Col>
          </Row>
          <div className="mt-3 px-3">
            <Table responsive bordered>
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>Product Name</th>
                  <th className="text-end">Price</th>
                  <th className="text-center">Quantity</th>
                  <th className="text-end">Sub Total</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => (
                  <tr key={index}>
                    <td>{product?.productCode}</td>
                    <td>{product?.productName}</td>
                    <td className="text-end">HK$ {product?.productPrice}</td>
                    <td className="text-center">{product?.qty}</td>
                    <td className="text-end">HK$ {product?.subTotal}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={4}>Delivery Fee</td>
                  <td className="text-end">HK$ {deliveryFee}</td>
                </tr>
                <tr>
                  <td colSpan={4}>Price Total</td>
                  <td className="text-end fw-bold">HK$ {totalFinal}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </DetailContainer>
      </div>
    </React.Fragment>
  );
};

export default DetailOrderComponent;
