import { Button, Modal, Popconfirm, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import React from 'react';

import ROUTE from '../../../Constants/route.constant';
import { DisplayFlex } from '../../../Assets/GlobalStyled';

const BurgundyTableComponent = ({ burgundies, onDelete, onSubDelete }) => {
  const [burgundy, setBurgundy] = React.useState(null);

  const navigate = useNavigate();

  const mapping = () => {
    return burgundies.map(burgundy => {
      return {
        ...burgundy,
        key: burgundy.id
      };
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Burgundy List',
      key: 'list',
      align: 'center',
      render: (_, record) => (
        <Button
          onClick={() => {
            setBurgundy(record);
          }}>
          View List
        </Button>
      )
    },
    {
      title: 'Update',
      key: 'editPage',
      render: (_, record) => (
        <DisplayFlex>
          <Button
            onClick={() => navigate(`/${ROUTE.burgundyUpdate}/${record.id}`)}>
            Edit Map
          </Button>
          <Popconfirm
            title="Delete the burgundy"
            description="Are you sure to delete this map?"
            onConfirm={() => onDelete(record?.id)}
            okText="Yes"
            cancelText="No">
            <Button danger={true}>Delete Map</Button>
          </Popconfirm>
        </DisplayFlex>
      )
    }
  ];

  const closeModal = () => {
    setBurgundy(null);
  };

  const _renderTableModal = () => {
    const { burgundy_subs = [] } = burgundy;
    const newColumn = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: 'Update',
        key: 'editPage',
        render: (_, record) => (
          <DisplayFlex>
            <Button
              onClick={() =>
                navigate(`/${ROUTE.burgundySubUpdate}/${record.id}`)
              }>
              Edit Map
            </Button>
            <Button
              danger={true}
              onClick={() => {
                onSubDelete(record?.id);
                closeModal();
              }}>
              Delete Map
            </Button>
          </DisplayFlex>
        )
      }
    ];

    const data = burgundy_subs?.map(item => {
      return {
        key: item?.id,
        ...item
      };
    });

    return (
      <Table
        pagination={false}
        columns={newColumn}
        dataSource={data}
        size="small"
      />
    );
  };

  return (
    <React.Fragment>
      <Table
        size="small"
        pagination={false}
        columns={columns}
        dataSource={mapping(burgundies)}
      />
      <Modal
        width={500}
        footer={[]}
        open={burgundy !== null}
        onOk={closeModal}
        onCancel={closeModal}>
        <DisplayFlex>
          <h5 className="p-1">{burgundy?.title}</h5>
          <Button
            className="m-1 mb-2"
            onClick={() => navigate(`/${ROUTE.burgundySubAdd}/${burgundy.id}`)}>
            Add New List
          </Button>
        </DisplayFlex>
        {burgundy && _renderTableModal()}
      </Modal>
    </React.Fragment>
  );
};

export default BurgundyTableComponent;
