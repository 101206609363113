import React from 'react';
import { Button, Input } from 'antd';
import { useDispatch } from 'react-redux';

import { NavigationBar, Pagination } from '../../Components';
import servicesRequest from '../../Utils/serviceRequest.utils';
import { GALLERY_URL } from '../../Utils/serviceUrl.utils';
import { TitleScreen, TitleSection } from './Gallery.styled';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import GalleryTableComponent from './Components/GalleryTable.component';

const GalleryContainer = () => {
  const [gallerys, setGallerys] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [search, setSearch] = React.useState('');

  const pageSize = 10;

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: {
          data: { totalItems, contents }
        }
      } = await request.get(GALLERY_URL, {
        params: {
          page: pages,
          size: pageSize,
          search
        }
      });

      setTotalItems(totalItems);
      setGallerys(contents);
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  const deletePost = async id => {
    try {
      const request = await servicesRequest();
      await request.delete(GALLERY_URL, { data: { id } });
      await fetching();
      showNotification('Gallery post successfully deleted');
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  const paginationProps = () => ({
    data: { total_page: totalItems, pageSize },
    onChangePage: page => {
      setPages(page - 1);
    }
  });

  React.useEffect(() => {
    fetching().catch(console.error);
  }, [pages]);

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetching().catch(console.error);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <NavigationBar>
      <TitleSection>
        <TitleScreen>Manage Gallery</TitleScreen>
        <Button href="/gallery/add">Add Gallery Post</Button>
      </TitleSection>
      <hr />
      <div className="mb-2 mt-2 w-25">
        <Input
          placeholder="Search gallery"
          onChange={e => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <GalleryTableComponent gallerys={gallerys} onDelete={deletePost} />
      <Pagination {...paginationProps()} />
    </NavigationBar>
  );
};

export default GalleryContainer;
