const ROUTE = {
  root: '',
  home: 'home',
  blog: 'blog',
  blogAdd: 'blog/add',
  blogUpdate: 'blog/update',
  news: 'news',
  newsAdd: 'news/add',
  newsUpdate: 'news/update',
  gallery: 'gallery',
  galleryAdd: 'gallery/add',
  galleryUpdate: 'gallery/update',
  contact: 'contact',
  faq: 'faq',
  discount: 'discount',
  discountAdd: 'discount/add',
  discountUpdate: 'discount/update',
  product: 'product',
  user: 'user',
  event: 'event',
  eventAdd: 'event/add',
  eventUpdate: 'event/update/:id',
  order: 'order',
  content: 'content',
  burgundy: 'burgundy',
  banner: 'banner',
  bannerAdd: 'banner/add',
  bannerUpdate: 'banner/update',
  burgundyAdd: 'burgundy/add',
  burgundyUpdate: 'burgundy/update',
  burgundySubAdd: 'burgundy/sub/add',
  burgundySubUpdate: 'burgundy/sub/update',
  shipment: 'shipment',
  payments: 'payments'
};

export default ROUTE;
