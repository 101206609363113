import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { Button } from 'antd';

import { NavigationBar } from '../../Components';
import { TitleScreen, TitleSection } from '../Blog/Blog.styled';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import servicesRequest from '../../Utils/serviceRequest.utils';
import { PURCHASE_URL } from '../../Utils/serviceUrl.utils';
import OrderTableComponent from './Components/OrderTable.component';
import { exportToCSV } from '../../Utils/general.utils';

const OrderContainer = () => {
  const [orders, setOrders] = React.useState([]);
  const [detailData, setDetailData] = React.useState(null);

  const dispatch = useDispatch();

  const fileName = 'order';

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const reformatOrders = orders.map(item => ({
    'Order Id': item?.id,
    'Order Date': moment(item?.createdAt).format('YYYY-MM-DD'),
    Amount: item?.totalQuantity,
    Price: item?.total,
    'Final Price': item?.totalAfterDiscount,
    Member: `${item?.user?.id} / ${item?.user?.name} / ${item?.user?.membershipType}`,
    'Payment Method': item?.payments?.paymentMethod,
    'Payment Status': item?.payments?.paymentStatus,
    'Shipment Method': item?.address?.shipmentMethod,
    'Shipment Status': item?.address?.deliveryStatus,
    'Shipment Destination': `${item?.address?.address} / ${item?.address?.postalCode} / ${item?.address?.addressNote}`
  }));

  const fetching = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.get(PURCHASE_URL);
      setOrders(data);
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, []);

  return (
    <NavigationBar>
      {detailData === null && (
        <React.Fragment>
          <TitleSection>
            <TitleScreen>Manage Order</TitleScreen>
            <Button onClick={() => exportToCSV(reformatOrders, fileName)}>
              Export File
            </Button>
          </TitleSection>
          <hr />
        </React.Fragment>
      )}

      <OrderTableComponent
        fetching={() => {
          fetching().catch(console.error);
        }}
        data={orders}
        detailData={detailData}
        setDetailData={setDetailData}
      />
    </NavigationBar>
  );
};

export default OrderContainer;
