import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { Button } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

import {
  InputImageBanner,
  InputTextEditor,
  NavigationBar
} from '../../../Components';
import {
  ButtonContainer,
  InputContainer,
  LabelForm,
  TextInput,
  TitleScreen,
  TitleSection
} from '../../Blog/Blog.styled';
import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';
import servicesRequest from '../../../Utils/serviceRequest.utils';
import { EVENT_URL } from '../../../Utils/serviceUrl.utils';
import ROUTE from '../../../Constants/route.constant';
import { DivDynamicForm } from '../Event.styled';
import EventModal from './EventModal.component';
import { FORM_TYPE } from '../../../Constants';
import { CancelButton, SaveButton } from '../../Menu/Menu.styled';

const COL = 3;

const defaultDynamicForm = [
  {
    name: 'Name',
    type: FORM_TYPE.PARAGRAPH,
    isRequired: true,
    option: ''
  },
  {
    name: 'Email',
    type: FORM_TYPE.PARAGRAPH,
    isRequired: true,
    option: ''
  },
  {
    name: 'Phone',
    type: FORM_TYPE.PARAGRAPH,
    isRequired: true,
    option: ''
  }
];

const AddEventComponent = () => {
  const [dynamicForm, setDynamicForm] = React.useState(defaultDynamicForm);
  const [show, setShow] = React.useState(false);
  const [banner, setBanner] = React.useState([]);
  const [gallery, setGallery] = React.useState([]);
  const [, updateState] = React.useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onAddForm = data => {
    const tempDynamicForm = dynamicForm;
    tempDynamicForm.push(data);
    setDynamicForm(tempDynamicForm);
    handleClose();
  };

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      title: '',
      event_date: '',
      event_time: '',
      venue: '',
      price: 0,
      createdAt: '',
      content: '',
      image: '',
      status: true,
      isActive: true
    }
  });

  const forceUpdate = React.useCallback(() => updateState({}), []);

  const onSubmit = async data => {
    try {
      const payload = {
        ...data,
        banner: banner.toString(),
        gallery: gallery.toString(),
        dynamicForm
      };

      const request = await servicesRequest();
      await request.post(EVENT_URL, payload);

      showNotification('Event successfully added');
      setTimeout(() => {
        navigate(`/${ROUTE.event}`);
      }, 2000);
    } catch (error) {
      showNotification('Failed to add');
    }
  };

  const handleUpdateDynamicBanner = (uri, index) => {
    let tempBanner = banner;
    tempBanner[index] = uri;
    setBanner(tempBanner);
    setValue('banner', tempBanner);
  };

  const addDynamicBanner = () => {
    let tempBanner = banner;
    tempBanner.push('');
    setBanner(tempBanner);
    setValue('banner', tempBanner);
    forceUpdate();
  };

  const deleteDynamicBanner = index => {
    let tempBanner = banner;
    tempBanner.splice(index, 1);
    setBanner(tempBanner);
    setValue('banner', tempBanner);
    forceUpdate();
  };

  const handleUpdateDynamicGallery = (uri, index) => {
    let tempGallery = gallery;
    tempGallery[index] = uri;
    setGallery(tempGallery);
    setValue('gallery', tempGallery);
  };

  const addDynamicGallery = () => {
    let tempGallery = gallery;
    tempGallery.push('');
    setGallery(tempGallery);
    setValue('gallery', tempGallery);
    forceUpdate();
  };

  const deleteDynamicGallery = index => {
    let tempGallery = gallery;
    tempGallery.splice(index, 1);
    setGallery(tempGallery);
    setValue('gallery', tempGallery);
    forceUpdate();
  };

  const renderDynamicBanner = () => {
    if (Array.isArray(banner)) {
      return (
        <>
          {banner?.map((item, index) => (
            <InputContainer key={index}>
              <Col xs={COL}>
                <LabelForm>Banner</LabelForm>
                <Button
                  icon={<DeleteFilled />}
                  onClick={() => deleteDynamicBanner(index)}
                />
              </Col>
              <Col>
                <Controller
                  name="banner"
                  control={control}
                  render={() => (
                    <InputImageBanner
                      getImageURL={uri => {
                        handleUpdateDynamicBanner(uri, index);
                      }}
                      value={item}
                    />
                  )}
                />
              </Col>
            </InputContainer>
          ))}
          <Button className="mt-2" onClick={addDynamicBanner}>
            Add more banner
          </Button>
        </>
      );
    }
  };

  const renderDynamicGallery = () => {
    if (Array.isArray(gallery)) {
      return (
        <>
          {gallery?.map((item, index) => (
            <InputContainer key={index}>
              <Col xs={COL}>
                <LabelForm>Gallery</LabelForm>
                <Button
                  icon={<DeleteFilled />}
                  onClick={() => deleteDynamicGallery(index)}
                />
              </Col>
              <Col>
                <Controller
                  name="banner"
                  control={control}
                  render={() => (
                    <InputImageBanner
                      getImageURL={uri => {
                        handleUpdateDynamicGallery(uri, index);
                      }}
                      value={item}
                    />
                  )}
                />
              </Col>
            </InputContainer>
          ))}
          <Button className="mt-2" onClick={addDynamicGallery}>
            Add more gallery
          </Button>
        </>
      );
    }
  };

  return (
    <React.Fragment>
      <NavigationBar>
        <TitleSection>
          <TitleScreen>Add Event</TitleScreen>
        </TitleSection>
        <hr />
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>Event Name</LabelForm>
          </Col>
          <Col>
            <Controller
              name="title"
              control={control}
              rules={{
                required: true
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  placeholder="Event Name"
                  error={errors?.title}
                />
              )}
            />
          </Col>
        </InputContainer>
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>Thumbnail</LabelForm>
          </Col>
          <Col>
            <InputImageBanner getImageURL={url => setValue('image', url)} />
          </Col>
        </InputContainer>
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>Event Date</LabelForm>
          </Col>
          <Col>
            <Controller
              name="event_date"
              control={control}
              rules={{
                required: true
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  type="date"
                  value={value}
                  onChange={onChange}
                  placeholder="Event Date"
                  error={errors?.event_date}
                />
              )}
            />
          </Col>
        </InputContainer>
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>Event Time</LabelForm>
          </Col>
          <Col>
            <Controller
              name="event_time"
              control={control}
              rules={{
                required: true
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  type="time"
                  value={value}
                  onChange={onChange}
                  placeholder="Event Time"
                  error={errors?.event_time}
                />
              )}
            />
          </Col>
        </InputContainer>
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>Venue</LabelForm>
          </Col>
          <Col>
            <Controller
              name="venue"
              control={control}
              rules={{
                required: true
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  placeholder="Event Venue"
                  error={errors?.venue}
                />
              )}
            />
          </Col>
        </InputContainer>
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>Price</LabelForm>
          </Col>
          <Col>
            <Controller
              name="price"
              control={control}
              rules={{
                required: true
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  type={'number'}
                  placeholder="Price"
                  error={errors?.price}
                />
              )}
            />
          </Col>
        </InputContainer>
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>Published Date</LabelForm>
          </Col>
          <Col>
            <Controller
              name="createdAt"
              control={control}
              rules={{
                required: true
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  type="date"
                  value={value}
                  onChange={onChange}
                  placeholder="Event Time"
                  error={errors?.createdAt}
                />
              )}
            />
          </Col>
        </InputContainer>
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>Published to Online Store</LabelForm>
          </Col>
          <Col>
            <Controller
              name="status"
              control={control}
              render={({ field: { onChange, value } }) => (
                <input
                  type="checkbox"
                  checked={value}
                  onChange={event => {
                    onChange(event.target.checked);
                  }}
                />
              )}
            />
          </Col>
        </InputContainer>
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>Allow user to register</LabelForm>
          </Col>
          <Col>
            <Controller
              name="isActive"
              control={control}
              render={({ field: { onChange, value } }) => (
                <input
                  type="checkbox"
                  checked={value}
                  onChange={event => {
                    onChange(event.target.checked);
                  }}
                />
              )}
            />
          </Col>
        </InputContainer>
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>Description</LabelForm>
          </Col>
          <Col>
            <InputTextEditor
              handleChange={description => setValue('content', description)}
            />
          </Col>
        </InputContainer>

        <InputContainer>
          <Col xs={COL}>
            <LabelForm>
              <b>Registration Form</b>{' '}
              <Button type="primary" onClick={handleShow}>
                Add Field
              </Button>
            </LabelForm>
          </Col>
        </InputContainer>
        <InputContainer>
          <Row className="g-1">
            {dynamicForm?.length > 0 &&
              dynamicForm.map((form, index) => (
                <Col md={3} key={index}>
                  <DivDynamicForm>
                    <b>{form?.name}</b> <br />
                    {form?.isRequired === true ? 'required | ' : ''}{' '}
                    {form?.type === FORM_TYPE.PARAGRAPH
                      ? 'short paragraph'
                      : 'multiple'}
                  </DivDynamicForm>
                </Col>
              ))}
          </Row>
        </InputContainer>
        <hr />
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>
              <b>Event Banner</b>{' '}
            </LabelForm>
          </Col>
          <Col md={12} xs={12} lg={12}>
            {renderDynamicBanner()}
          </Col>
        </InputContainer>

        <InputContainer>
          <Col xs={COL}>
            <LabelForm>
              <b>Gallery</b>{' '}
            </LabelForm>
          </Col>
          <Col md={12} xs={12} lg={12}>
            {renderDynamicGallery()}
          </Col>
        </InputContainer>
        <hr />
        <ButtonContainer>
          <CancelButton onClick={() => navigate(-1)}>Cancel</CancelButton>
          <SaveButton onClick={handleSubmit(onSubmit)}>Save</SaveButton>
        </ButtonContainer>
      </NavigationBar>
      <EventModal handleClose={handleClose} onAddForm={onAddForm} show={show} />
    </React.Fragment>
  );
};

export default AddEventComponent;
