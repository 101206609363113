import styled from 'styled-components';

import { DisplayFlex } from '../../Assets/GlobalStyled';
import { Row } from 'react-bootstrap';

export const TitleSection = styled(DisplayFlex)`
  justify-content: space-between;
`;

export const TitleScreen = styled.h4`
  font-family: 'ABeeZee', sans-serif;
  //font-style: italic;
  font-weight: 500;
  margin: 0;
`;

export const InputContainer = styled(Row)`
  justify-items: center;
  margin: 1em 0;
`;

export const TextInput = styled.input`
  background: #e0e0e0;
  opacity: 0.5;
  border-radius: 3px;
  border: ${props => (props.error ? '1px solid #DA2F24' : 'none')};
  width: 100%;
  padding: 0.5em;

  &:focus {
    outline: none;
  }
`;

export const LabelForm = styled.p`
  font-family: 'ABeeZee', sans-serif;
  font-style: italic;
  font-weight: 400;
  color: #324158;
  opacity: 0.8;
  margin: 0;
  padding: 0;
`;

export const ButtonContainer = styled(DisplayFlex)`
  justify-items: center;
  justify-content: center;
  gap: 0.5em;
`;
