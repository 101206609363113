import styled from 'styled-components';

export const DivDynamicForm = styled.div`
  border-radius: 8px;
  font-family: 'ABeeZee', sans-serif;
  font-style: italic;
  border: 1px solid #e0e0e0;
  padding: 1em;
  min-height: 5em;
  margin: auto;
`;
