import React from 'react';
import { Button, Input } from 'antd';
import { useDispatch } from 'react-redux';

import { NavigationBar, Pagination } from '../../Components';
import servicesRequest from '../../Utils/serviceRequest.utils';
import { BLOG_URL } from '../../Utils/serviceUrl.utils';
import { TitleScreen, TitleSection } from './Blog.styled';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import BlogTableComponent from './Components/BlogTable.component';

const BlogContainer = () => {
  const [blogs, setBlogs] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [search, setSearch] = React.useState('');

  const pageSize = 10;

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: {
          data: { totalItems, contents }
        }
      } = await request.get(BLOG_URL, {
        params: {
          page: pages,
          size: pageSize,
          search
        }
      });

      setTotalItems(totalItems);
      setBlogs(contents);
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  const deletePost = async id => {
    try {
      const request = await servicesRequest();
      await request.delete(BLOG_URL, { data: { id } });
      await fetching();
      showNotification('Blog post successfully deleted');
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, [pages]);

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetching().catch(console.error);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <NavigationBar>
      <TitleSection>
        <TitleScreen>Manage Blog</TitleScreen>
        <Button href="/blog/add">Add Blog Post</Button>
      </TitleSection>
      <hr />
      <div className="mb-2 mt-2 w-25">
        <Input
          placeholder="Search blog"
          onChange={e => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <BlogTableComponent blogs={blogs} onDelete={deletePost} />
      <Pagination
        data={{ total_item: totalItems, itemperpage: pageSize }}
        onChangePage={page => {
          setPages(page - 1);
        }}
      />
    </NavigationBar>
  );
};

export default BlogContainer;
