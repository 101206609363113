import React from 'react';
import { Button, Popconfirm } from 'antd';

const DeleteBlogConfirmComponent = ({
  id,
  onDelete,
  title = 'Delete confirmation',
  description = 'Are you sure to delete this?',
  danger = true,
  label = 'Delete'
}) => {
  const [open, setOpen] = React.useState(false);
  const condition = false;

  const confirm = () => {
    setOpen(false);
    onDelete(id);
  };

  const cancel = () => {
    setOpen(false);
  };

  const handleOpenChange = newOpen => {
    if (!newOpen) {
      setOpen(newOpen);
      return;
    }
    if (condition) {
      confirm();
    } else {
      setOpen(newOpen);
    }
  };

  return (
    <Popconfirm
      title={title}
      description={description}
      open={open}
      onOpenChange={handleOpenChange}
      onConfirm={confirm}
      onCancel={cancel}
      okText="Yes"
      cancelText="No">
      <Button danger={danger}>{label}</Button>
    </Popconfirm>
  );
};

export default DeleteBlogConfirmComponent;
