import styled from 'styled-components';
import { Table as TableBS } from 'react-bootstrap';

export const ColTitle = styled.h5`
  background-color: #f9fafb;
  border-radius: 8px;
  color: #4e5157;
`;

export const DetailContainer = styled.div`
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 0.5vh;
`;

export const Table = styled(TableBS)`
  border-radius: 8px !important;
`;
