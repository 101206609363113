import React from 'react';
import axios from 'axios';

import {
  InnerTitleDescription,
  InputImageContainer
} from './InputImageBaner.styled';
import { UPLOAD_IMAGE_URL } from '../../Utils/serviceUrl.utils';

const InputImageBanner = ({ getImageURL, value = '' }) => {
  const [selectedFile, setSelectedFile] = React.useState(value);
  const inputFileRef = React.useRef();

  const onSelectFile = async event => {
    if (!event.target.files || event.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    await uploadImage(event.target.files[0]);
  };

  React.useEffect(() => {
    setSelectedFile(value);
  }, [value]);

  React.useEffect(() => {
    getImageURL(selectedFile);
  }, [selectedFile]);

  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  const uploadImage = async image => {
    try {
      const data = new FormData();
      data.append('image', image);
      const {
        data: { data: imageURL }
      } = await axios.post(UPLOAD_IMAGE_URL, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('wb-token')}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      setSelectedFile(imageURL);
    } catch (error) {
    }
  };

  return (
    <InputImageContainer onClick={onBtnClick}>
      <div>
        {selectedFile ? (
          <>
            {selectedFile && (
              <img
                src={selectedFile}
                style={{
                  width: '100%',
                  height: '200px'
                }}
                alt="images"
              />
            )}
          </>
        ) : (
          <>
            <div>
              <InnerTitleDescription>
                Drop your product images here or Browse
              </InnerTitleDescription>
              <br />
              <InnerTitleDescription>
                JPG, PNG, SVG accepted <br />
                Max. file size 20MB. Max. 1 image. Recommended image size 390 × 280 pixels
              </InnerTitleDescription>
            </div>
          </>
        )}
      </div>
      <div style={{ display: 'none' }}>
        <input ref={inputFileRef} type="file" onChange={onSelectFile} />
      </div>
    </InputImageContainer>
  );
};

export default InputImageBanner;
