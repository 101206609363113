import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Container, FormLabel, Input, Logo, Title } from './Login.styled';
import LogoWineBuff from '../../Assets/Images/winebuff-logo.png';
import { Button } from '../../Assets/GlobalStyled';
import { SIGN_IN_URL } from '../../Utils/serviceUrl.utils';
import servicesRequest from '../../Utils/serviceRequest.utils';
import ROUTE from '../../Constants/route.constant';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';

const LoginScreen = () => {
  const [data, setData] = React.useState({ email: '', password: '' });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const onSubmit = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: {
          data: { token }
        }
      } = await request.post(SIGN_IN_URL, data);
      localStorage.setItem('wb-token', token);
      navigate(ROUTE.product);
    } catch (error) {
      showNotification('Sorry, your account is not found');
    }
  };

  return (
    <Container>
      <Row className="justify-content-center align-items-center gy-5">
        <Col md={12}>
          <Logo
            src={LogoWineBuff}
            className="rounded mx-auto d-block img-fluid"
          />
          <Title>CMS Portal</Title>
        </Col>
        <Col md={6}>
          <Row className="gy-2 justify-content-center align-content-center align-items-center pe-5 ps-5">
            <Col md={4}>
              <FormLabel>Email</FormLabel>
            </Col>
            <Col md={8}>
              <Input
                placeholder="Email"
                type="email"
                onChange={event =>
                  setData({ ...data, email: event.target.value })
                }
              />
            </Col>
            <Col md={4}>
              <FormLabel>Password</FormLabel>
            </Col>
            <Col md={8}>
              <Input
                placeholder="Password"
                type="password"
                onChange={event =>
                  setData({ ...data, password: event.target.value })
                }
              />
            </Col>
          </Row>
          <Col className="text-center mt-4">
            <Button onClick={onSubmit}>Login</Button>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginScreen;
