import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const DisplayFlex = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.5vw;
`;

export const Button = styled.button`
  text-decoration: none;
  background: #2f80ed;
  box-shadow: 5px 5px 15px 3px rgba(50, 65, 88, 0.25);
  border-radius: 5px;
  border: none;
  color: #ffffff;
  padding: 0.3em 2em;

  font-weight: 700;
  line-height: 28px;

  &:hover {
    color: #ffffff;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const StyledHref = styled.a`
  text-decoration: none;
  color: white;

  &:hover {
    color: white;
    opacity: 1;
  }
`;
