import { Controller, useForm } from 'react-hook-form';
import { Col, Form } from 'react-bootstrap';
import React from 'react';

import servicesRequest from '../../../Utils/serviceRequest.utils';
import { SHIPMENT_URL } from '../../../Utils/serviceUrl.utils';
import {
  ButtonContainer,
  InputContainer,
  LabelForm,
  TextInput
} from '../../Blog/Blog.styled';
import { CancelButton, SaveButton } from '../../Menu/Menu.styled';

const COL = 3;

const UpdateShipmentComponent = ({
  onCancel,
  showNotification,
  data,
  fetching
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
      shipmentId: '',
      isAddressRequired: true,
      fee: 0,
      freeThreshold: 0
    }
  });

  React.useEffect(() => {
    setValue('title', data?.title);
    setValue('description', data?.description);
    setValue('isAddressRequired', data?.isAddressRequired);
    setValue('shipmentId', data?.id);
    setValue('fee', data?.fee);
    setValue('freeThreshold', data?.freeThreshold);
  }, [data]);

  const onSubmit = async data => {
    try {
      const request = await servicesRequest();
      await request.patch(SHIPMENT_URL, data);

      await fetching();
      showNotification('Shipment method successfully updated');
    } catch (error) {
      showNotification('Shipment method failed to updated');
    } finally {
      onCancel();
    }
  };
  return (
    <React.Fragment>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm className="pt-2">Title</LabelForm>
        </Col>
        <Col>
          <Controller
            name="title"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="Title"
                error={errors?.title}
              />
            )}
          />
        </Col>
      </InputContainer>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm className="pt-2">Description</LabelForm>
        </Col>
        <Col>
          <Controller
            name="description"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="Description"
                error={errors?.description}
              />
            )}
          />
        </Col>
      </InputContainer>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm className="pt-2">Fee</LabelForm>
        </Col>
        <Col>
          <Controller
            name="fee"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="Fee"
                error={errors?.fee}
              />
            )}
          />
        </Col>
      </InputContainer>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm className="pt-2">Free Threshold</LabelForm>
        </Col>
        <Col>
          <Controller
            name="freeThreshold"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="Free Threshold"
                error={errors?.freeThreshold}
              />
            )}
          />
        </Col>
      </InputContainer>
      <InputContainer>
        <Col>
          <Controller
            name="isAddressRequired"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Form.Group className="" controlId="formBasicCheckbox">
                <Form.Check
                  checked={value}
                  type="checkbox"
                  label="Address Required"
                  onClick={onChange}
                />
              </Form.Group>
            )}
          />
        </Col>
      </InputContainer>

      <ButtonContainer className="mt-4">
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton onClick={handleSubmit(onSubmit)}>Save</SaveButton>
      </ButtonContainer>
    </React.Fragment>
  );
};

export default UpdateShipmentComponent;
