import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Table } from 'antd';
import { Button } from 'antd';

import servicesRequest from '../../../Utils/serviceRequest.utils';
import { CONTACT_ENQUIRIES_URL } from '../../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';
import { exportToCSV } from '../../../Utils/general.utils';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country'
  },
  {
    title: 'Company Name',
    dataIndex: 'company_name',
    key: 'company_name'
  },
  {
    title: 'Interested In',
    dataIndex: 'interested_in',
    key: 'interested_in'
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message'
  },
  {
    title: 'Time',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: text => <p>{moment(text).format('MMM DD, YYYY')}</p>
  }
];

const ContactEnquiriesComponent = () => {
  const [contacts, setContacts] = React.useState([]);

  const dispatch = useDispatch();

  const fileName = 'contacts';

  const reformatOrders = contacts.map(item => ({
    'Name': item?.name,
    'Email': item?.email,
    'Phone': item?.phone,
    'Country': item?.country,
    'Company Name': item?.company_name,
    'Interested In': item?.interested_in,
    'Message': item?.message,
    'Time': moment(item?.createdAt).format('YYYY-MM-DD'),
  }));

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(CONTACT_ENQUIRIES_URL);

      setContacts(data);
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  React.useEffect(() => {
    fetching();
  }, []);

  const mapping = () => {
    return contacts.map(contact => {
      return {
        ...contact,
        key: contact.id
      };
    });
  };

  return (
  <>
    <Button onClick={() => exportToCSV(reformatOrders, fileName)}>
      Export File
    </Button>
    <Table columns={columns} dataSource={mapping()} size="small" />
  </>
  )
};

export default ContactEnquiriesComponent;
