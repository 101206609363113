import React from 'react';
import { Button, Input } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { NavigationBar, Pagination } from '../../Components';
import { TitleScreen, TitleSection } from '../Blog/Blog.styled';
import ROUTE from '../../Constants/route.constant';
import EventTableComponent from './Components/EventTable.component';
import servicesRequest from '../../Utils/serviceRequest.utils';
import { EVENT_URL } from '../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import { exportToCSV } from '../../Utils/general.utils';
import { DisplayFlex } from '../../Assets/GlobalStyled';

const EventContainer = () => {
  const [events, setEvents] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [search, setSearch] = React.useState('');

  const pageSize = 10;

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: {
          data: { totalItems, contents }
        }
      } = await request.get(EVENT_URL, {
        params: {
          page: pages,
          size: pageSize,
          search
        }
      });

      setTotalItems(totalItems);
      setEvents(contents);
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  const onDelete = async id => {
    try {
      const request = await servicesRequest();
      await request.post(EVENT_URL + '/delete', { eventId: id });

      fetching().catch(console.error);

      showNotification('Event deleted');
    } catch (error) {
      showNotification('Delete failed, please try again');
    }
  };

  const paginationProps = () => ({
    data: { total_page: totalItems, pageSize },
    onChangePage: page => {
      setPages(page - 1);
    }
  });

  React.useEffect(() => {
    fetching().catch(console.error);
  }, [pages]);

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetching().catch(console.error);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const fileName = 'event';

  const reformatEvents = events?.map(item => ({
    'Event Id': item?.id,
    Title: item?.title,
    'Event Created': moment(item?.createdAt).format('YYYY-MM-DD'),
    'Event Date': moment(item?.event_date).format('YYYY-MM-DD'),
    'Event Time': item?.event_time,
    Image: item?.image,
    Status: item?.status === true ? 'Active' : 'Nonactive',
    'Registered Users': item?.event_orders?.length,
    'Is Active': item?.isActive
  }));

  return (
    <NavigationBar>
      <TitleSection>
        <TitleScreen>Manage Event</TitleScreen>
        <Button href={'/' + ROUTE.eventAdd}>Add New Event</Button>
      </TitleSection>
      <hr />
      <div className="mb-2 mt-2 w-25">
        <DisplayFlex style={{ gap: '2px' }}>
          <Input
            placeholder="Search event"
            onChange={e => {
              setSearch(e.target.value);
            }}
          />
          <Button onClick={() => exportToCSV(reformatEvents, fileName)}>
            Export File
          </Button>
        </DisplayFlex>
      </div>

      <EventTableComponent events={events} onDelete={onDelete} />
      <Pagination {...paginationProps()} />
    </NavigationBar>
  );
};

export default EventContainer;
