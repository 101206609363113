import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Input } from 'antd';

import { NavigationBar } from '../../Components';
import ProductTableComponent from './Components/ProductTable.component';
import { TitleScreen, TitleSection } from '../Blog/Blog.styled';
import servicesRequest from '../../Utils/serviceRequest.utils';
import { PRODUCT_URL } from '../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import { DisplayFlex } from '../../Assets/GlobalStyled';

const ProductContainer = () => {
  const [products, setProducts] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [item, setItem] = React.useState(12);
  const [pagination, setPagination] = React.useState({
    page: '1',
    total_page: '1',
    total_item: '1',
    itemperpage: '20'
  });
  const [search, setSearch] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingStock, setIsLoadingStock] = React.useState(false);
  const [onlineStore, setOnlineStore] = React.useState('1');

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const fetching = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.post(PRODUCT_URL, {
        itemCode: '',
        categoryCode: '',
        itemName: search,
        itemPerPage: item,
        pageCount: page,
        onlineStore
      });

      setProducts(data.products);
      setPagination(data.pagination);
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  const syncMaster = async () => {
    setIsLoading(true);
    try {
      const authorizedRequest = await servicesRequest();
      await authorizedRequest.get(PRODUCT_URL + '/sync');

      showNotification('Product sync success');
    } catch (error) {
      showNotification('Product sync failed');
    } finally {
      setIsLoading(false);
    }
  };

  const syncStock = async () => {
    setIsLoadingStock(true);
    try {
      const authorizedRequest = await servicesRequest();
      await authorizedRequest.get(PRODUCT_URL + '/sync-stock');

      showNotification('Product stock sync success');
    } catch (error) {
      showNotification('Product stock sync failed');
    } finally {
      setIsLoadingStock(false);
    }
  };

  const updateProductStatus = async code => {
    try {
      const authorizedRequest = await servicesRequest();
      await authorizedRequest.post(`${PRODUCT_URL}/unpublish`, {
        product: code
      });
      await fetching();
      showNotification('Product status updated');
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  const onChangePage = requestPage => {
    setPage(requestPage);
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, [page]);

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetching().catch(console.error);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, onlineStore]);

  const props = () => ({
    products,
    page,
    setPage,
    item,
    setItem,
    pagination,
    setPagination,
    updateProductStatus,
    onChangePage
  });

  return (
    <NavigationBar>
      <TitleSection>
        <TitleScreen>Manage Product</TitleScreen>
        <div>
          <Button
            className="mx-1"
            type="primary"
            loading={isLoading}
            onClick={() => {
              syncMaster().catch(console.error);
            }}>
            Sync master products
          </Button>
          <Button
            type="dashed"
            loading={isLoadingStock}
            onClick={() => {
              syncStock().catch(console.error);
            }}>
            Sync stock numbers
          </Button>
        </div>
      </TitleSection>
      <hr />
      <div className="mb-2 mt-2 w-50">
        <DisplayFlex style={{ gap: '1vw' }}>
          <Input
            placeholder="Search product"
            onChange={e => {
              setSearch(e.target.value);
            }}
          />
          <Button
            loading={isLoading}
            onClick={() => {
              setOnlineStore('1');
            }}>
            Online Store Products
          </Button>
          <Button
            loading={isLoading}
            onClick={() => {
              setOnlineStore('0');
            }}>
            Non Online Store Products
          </Button>
        </DisplayFlex>
      </div>
      <ProductTableComponent {...props()} />
    </NavigationBar>
  );
};

export default ProductContainer;
