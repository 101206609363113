import React from 'react';
import { Button, Dropdown, Table } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { numberWithCommas, reformatString } from '../../../Utils/general.utils';
import { StyledHref } from '../../../Assets/GlobalStyled';
import servicesRequest from '../../../Utils/serviceRequest.utils';
import { PURCHASE_URL } from '../../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';
import { DELIVERY_STATUS, PAYMENT_STATUS } from '../../../Constants';
import DetailOrderComponent from './DetailOrder.component';

const OrderTableComponent = ({ data, fetching, setDetailData, detailData }) => {
  const items = [];
  for (const delivery in DELIVERY_STATUS) {
    items.push({
      key: DELIVERY_STATUS[delivery],
      label: (
        <StyledHref>{reformatString(DELIVERY_STATUS[delivery])}</StyledHref>
      )
    });
  }

  const paymentStatus = [];
  for (const payments in PAYMENT_STATUS) {
    paymentStatus.push({
      key: PAYMENT_STATUS[payments],
      label: <StyledHref>{reformatString(PAYMENT_STATUS[payments])}</StyledHref>
    });
  }

  const mapping = () => {
    return data.map(item => {
      return {
        ...item,
        key: item.id
      };
    });
  };

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const handleDropdownItemClick = async (event, orderId) => {
    try {
      const payload = {
        status: event.key,
        orderId: orderId
      };
      const authorizedRequest = await servicesRequest();
      await authorizedRequest.patch(PURCHASE_URL + '/status', payload);
      await fetching();
      showNotification('Delivery status updated');
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  const handleUpdatePaymentStatus = async (event, orderId) => {
    try {
      const payload = {
        status: event.key,
        orderId: orderId
      };
      const authorizedRequest = await servicesRequest();
      await authorizedRequest.patch(PURCHASE_URL + '/status/payment', payload);
      await fetching();
      showNotification('Payment status updated');
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  const columns = [
    {
      title: 'Order No',
      dataIndex: 'id',
      key: 'id',
      width: '15%'
    },
    {
      title: 'Order Date',
      align: 'center',
      render: (_, record) => (
        <>{moment(record?.createdAt).format('YYYY-MM-DD')}</>
      )
    },
    {
      title: 'Qty',
      align: 'right',
      render: (_, record) => <>{numberWithCommas(record?.totalQuantity)}</>
    },
    {
      title: 'Total',
      align: 'center',
      render: (_, record) => <>HKD {numberWithCommas(record?.totalFinal)}</>
    },
    {
      title: 'Payment Method',
      align: 'center',
      render: (_, record) => (
        <>{reformatString(record?.payments?.paymentMethod)}</>
      )
    },
    {
      title: 'Card Type',
      align: 'center',
      render: (_, record) => <>{record?.payments?.cardType}</>
    },
    {
      title: 'Transaction Number',
      align: 'center',
      render: (_, record) => <>{record?.payments?.paymentTraceId}</>
    },
    {
      title: 'Payment Status',
      align: 'center',
      render: (_, record) => (
        <>
          <Dropdown
            trigger="click"
            menu={{
              onClick: event => handleUpdatePaymentStatus(event, record.id),
              items: paymentStatus
            }}
            placement="bottomLeft">
            <Button>
              {reformatString(record?.payments?.paymentStatus) || 'Pending'}
            </Button>
          </Dropdown>
        </>
      )
    },
    {
      title: 'Delivery Fee',
      align: 'center',
      render: (_, record) => <>HKD {numberWithCommas(record?.deliveryFee)}</>
    },
    {
      title: 'Delivery Status',
      align: 'center',
      render: (_, record) => (
        <>
          <Dropdown
            trigger="click"
            menu={{
              onClick: event => handleDropdownItemClick(event, record.id),
              items
            }}
            placement="bottomLeft">
            <Button>
              {reformatString(record?.address?.deliveryStatus) || 'Pending'}
            </Button>
          </Dropdown>
        </>
      )
    },
    {
      title: 'More',
      align: 'center',
      width: '15%',
      render: (_, record) => (
        <Button
          onClick={() => {
            setDetailData(record);
          }}>
          View Details
        </Button>
      )
    }
  ];

  if (detailData !== null) {
    return (
      <DetailOrderComponent data={detailData} setDetailData={setDetailData} />
    );
  }

  return (
    <React.Fragment>
      <Table columns={columns} dataSource={mapping()} size="small" />
    </React.Fragment>
  );
};

export default OrderTableComponent;
