import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { NavigationBar } from '../../Components';
import { TitleScreen, TitleSection } from '../Blog/Blog.styled';
import servicesRequest from '../../Utils/serviceRequest.utils';
import { DISCOUNT_URL } from '../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import DiscountTableComponent from './Components/DiscountTable.component';
import ROUTE from '../../Constants/route.constant';

const DiscountContainer = () => {
  const [discounts, setDiscounts] = React.useState([]);

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(DISCOUNT_URL);
      setDiscounts(data);
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, []);

  return (
    <NavigationBar>
      <TitleSection>
        <TitleScreen>Manage Discount</TitleScreen>
        <Button href={'/' + ROUTE.discountAdd}>Add New Discount</Button>
      </TitleSection>
      <hr />
      <DiscountTableComponent discounts={discounts} />
    </NavigationBar>
  );
};

export default DiscountContainer;
