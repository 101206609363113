import React from 'react';
import { Button, Input, Modal } from 'antd';
import { useDispatch } from 'react-redux';

import { TitleScreen, TitleSection } from '../Blog/Blog.styled';
import { NavigationBar, Pagination } from '../../Components';
import servicesRequest from '../../Utils/serviceRequest.utils';
import { SHIPMENT_URL } from '../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import AddShipmentComponent from './Components/AddShipment.component';
import ShipmentTableComponent from './Components/ShipmentTable.component';
import UpdateShipmentComponent from './Components/UpdateShipment.component';

const ShipmentContainer = () => {
  const [shipments, setShipments] = React.useState([]);
  const [addModal, setAddModal] = React.useState(false);
  const [updateModal, setUpdateModal] = React.useState(false);
  const [dataToEdit, setDataToEdit] = React.useState(null);
  const [totalItems, setTotalItems] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const [pages, setPages] = React.useState(0);

  const pageSize = 10;
  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: {
          data: { totalItems, contents }
        }
      } = await request.get(SHIPMENT_URL, {
        params: {
          page: pages,
          size: pageSize,
          search
        }
      });

      setTotalItems(totalItems);
      setShipments(contents);
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, [pages]);

  const handleCloseModal = () => {
    setAddModal(false);
    setUpdateModal(false);
    setDataToEdit(null);
  };

  const setUpdateModalActive = data => {
    setUpdateModal(true);
    setDataToEdit(data);
  };

  const paginationProps = () => ({
    data: { total_page: totalItems, pageSize },
    onChangePage: page => {
      setPages(page - 1);
    }
  });

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetching().catch(console.error);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <NavigationBar>
      <TitleSection>
        <TitleScreen>Shipment Method</TitleScreen>
        <Button onClick={() => setAddModal(true)}>Add Shipment Method</Button>
      </TitleSection>
      <hr />
      <div className="mb-2 mt-2 w-25">
        <Input
          placeholder="Search shipment method"
          onChange={e => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Modal
        width={700}
        footer={[]}
        title="Add New Shipment Method"
        open={addModal}
        onCancel={() => {
          handleCloseModal();
        }}>
        <AddShipmentComponent
          showNotification={text => {
            showNotification(text);
          }}
          fetching={fetching}
          onCancel={handleCloseModal}
        />
      </Modal>
      <Modal
        width={700}
        footer={[]}
        title="Update Shipment Method"
        open={updateModal}
        onCancel={() => {
          handleCloseModal();
        }}>
        <UpdateShipmentComponent
          data={dataToEdit}
          showNotification={text => {
            showNotification(text);
          }}
          onCancel={handleCloseModal}
          fetching={fetching}
        />
      </Modal>
      <ShipmentTableComponent
        setUpdateModalActive={setUpdateModalActive}
        shipments={shipments}
      />
      <Pagination {...paginationProps()} />
    </NavigationBar>
  );
};

export default ShipmentContainer;
