export const DISCOUNT_METHOD = {
  AUTO: 'DISCOUNT_AUTO',
  CODE: 'DISCOUNT_CODE'
};

export const DISCOUNT_TYPE = {
  FIXED: 'FIXED',
  PERCENTAGE: 'PERCENTAGE'
};

export const DISCOUNT_CRITERIA = {
  NO_CRITERIA: 'NO_CRITERIA',
  MIN_PURCHASE_AMOUNT: 'MIN_PURCHASE_AMOUNT',
  MIN_ORDER_AMOUNT: 'MIN_ORDER_AMOUNT'
};

export const DELIVERY_STATUS = {
  PENDING: 'PENDING',
  ORDER_PROCESSING: 'ORDER_PROCESSING',
  SHIPPED: 'SHIPPED',
  SHIPPING: 'SHIPPING',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED'
};

export const FORM_TYPE = {
  MULTIPLE: 'MULTIPLE',
  PARAGRAPH: 'PARAGRAPH'
};

export const PAYMENT_STATUS = {
  PENDING: 'PENDING',
  PAID: 'PAID',
  CANCELLED: 'CANCELLED',
  REFUNDED: 'REFUNDED'
};
