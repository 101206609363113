import React from 'react';
import { Button, Modal, Table } from 'antd';
import { useDispatch } from 'react-redux';

import PaginationComponent from '../../../Components/Pagination/Pagination.component';
import { numberWithCommas } from '../../../Utils/general.utils';
import UpdateProductImageComponent from './UpdateProductImage.component';
import servicesRequest from '../../../Utils/serviceRequest.utils';
import { PRODUCT_URL } from '../../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';

const ProductTableComponent = props => {
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [tempImageToUpdate, setTempImageToUpdate] = React.useState('');

  const { products } = props;
  const mapping = () => {
    return products.map(product => {
      return {
        ...product,
        key: product.code
      };
    });
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code)
    },
    {
      title: 'Name',
      dataIndex: 'name_en',
      key: 'name_en',
      sorter: (a, b) => a.name_en.localeCompare(b.name_en)
    },
    {
      title: 'Category',
      dataIndex: 'category_id',
      key: 'category_id',
      sorter: (a, b) => a.category_id.localeCompare(b.category_id)
    },
    {
      title: 'Price',
      align: 'right',
      dataIndex: 'bprice',
      render: (_, record) => <>HK${numberWithCommas(record?.bprice)}</>,
      sorter: (a, b) => a.bprice - b.bprice
    },
    {
      title: 'Action',
      align: 'center',
      render: (_, record) => (
        <>
          <Button onClick={() => setSelectedProduct(record)}>
            Update Image
          </Button>
        </>
      )
    }
    // {
    //   title: 'Status',
    //   dataIndex: 'publish',
    //   align: 'center',
    //   render: (_, record) => (
    //     <>
    //       {record?.publish ? (
    //         <DeleteBlogConfirmComponent
    //           id={record?.code}
    //           onDelete={updateStatus}
    //           danger={false}
    //           label="Published"
    //           title="Confirmation"
    //           description="Are you sure want to unpublish this products?"
    //         />
    //       ) : (
    //         <DeleteBlogConfirmComponent
    //           id={record?.code}
    //           onDelete={updateStatus}
    //           danger={true}
    //           label="Unpublished"
    //           title="Confirmation"
    //           description="Are you sure want to publish this products?"
    //         />
    //       )}
    //     </>
    //   )
    // }
  ];

  const newProps = () => ({
    ...props,
    data: props.pagination
  });

  const closeModal = () => {
    setSelectedProduct(null);
    setTempImageToUpdate('');
  };

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const updateImage = async () => {
    try {
      const payload = {
        code: selectedProduct?.code,
        image: tempImageToUpdate
      };

      const request = await servicesRequest();
      await request.patch(PRODUCT_URL, payload);

      showNotification('Product image successfully updated');
      setTimeout(() => {
        closeModal();
      }, 2000);
    } catch (error) {
      showNotification('Failed to updated');
    }
  };

  return (
    <React.Fragment>
      <Table
        columns={columns}
        dataSource={mapping()}
        size="small"
        pagination={false}
      />
      <PaginationComponent {...newProps()} />

      <Modal
        width={1000}
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={updateImage}>
            Update
          </Button>
        ]}
        title={selectedProduct?.name}
        open={selectedProduct !== null}
        onOk={closeModal}
        onCancel={closeModal}>
        {selectedProduct !== null && (
          <UpdateProductImageComponent
            getImageURL={img => {
              setTempImageToUpdate(img);
            }}
            value={selectedProduct?.image}
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

export default ProductTableComponent;
