import React from 'react';
import { Button, Modal } from 'antd';
import { Col } from 'react-bootstrap';

import {
  InputContainer,
  LabelForm,
  TextInput,
  TitleScreen
} from '../../Blog/Blog.styled';

const branchTemplate = {
  id: new Date().getTime(),
  storeName: '',
  address: '',
  storeContact: '',
  workHour: '',
  maps: ''
};

const BranchModalComponent = ({ isOpen, onCancel, onOk, dataToEdit }) => {
  const [data, setData] = React.useState(branchTemplate);

  const handleSubmit = () => {
    let reformatPayload = data;
    if (dataToEdit !== null) {
      reformatPayload.index = dataToEdit?.index;
    }

    onOk({ ...reformatPayload });
    setData(branchTemplate);
  };

  const onClose = () => {
    setData(branchTemplate);
    onCancel();
  };

  React.useEffect(() => {
    if (dataToEdit !== null) {
      setData(dataToEdit?.item);
    } else {
      setData(branchTemplate);
    }
  }, [dataToEdit]);

  return (
    <Modal
      open={isOpen}
      onOk={handleSubmit}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Save
        </Button>
      ]}>
      <TitleScreen>Branch Information</TitleScreen>
      <hr />

      <InputContainer className="justify-content-md-center">
        <Col xs={3}>
          <LabelForm>Store</LabelForm>
        </Col>
        <Col>
          <TextInput
            onChange={event =>
              setData({
                ...data,
                storeName: event.target.value
              })
            }
            value={data?.storeName}
            placeholder="Store Name"
          />
        </Col>
      </InputContainer>

      <InputContainer className="justify-content-md-center">
        <Col xs={3}>
          <LabelForm>Address</LabelForm>
        </Col>
        <Col>
          <TextInput
            onChange={event =>
              setData({
                ...data,
                address: event.target.value
              })
            }
            value={data?.address}
            placeholder="Address"
          />
        </Col>
      </InputContainer>

      <InputContainer className="justify-content-md-center">
        <Col xs={3}>
          <LabelForm>Contact</LabelForm>
        </Col>
        <Col>
          <TextInput
            onChange={event =>
              setData({
                ...data,
                storeContact: event.target.value
              })
            }
            value={data?.storeContact}
            placeholder="Store Contact"
          />
        </Col>
      </InputContainer>

      <InputContainer className="justify-content-md-center">
        <Col xs={3}>
          <LabelForm>Work Hour</LabelForm>
        </Col>
        <Col>
          <TextInput
            onChange={event =>
              setData({
                ...data,
                workHour: event.target.value
              })
            }
            value={data?.workHour}
            placeholder="Work Hour"
          />
        </Col>
      </InputContainer>

      <InputContainer className="justify-content-md-center">
        <Col xs={3}>
          <LabelForm>Iframe Maps</LabelForm>
        </Col>
        <Col>
          <TextInput
            onChange={event =>
              setData({
                ...data,
                maps: event.target.value
              })
            }
            value={data?.maps}
            placeholder="Iframe Maps"
          />
        </Col>
      </InputContainer>
    </Modal>
  );
};

export default BranchModalComponent;
