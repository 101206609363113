import styled from 'styled-components';

import { DisplayFlex } from '../../Assets/GlobalStyled';

export const Container = styled.div`
  padding: 0.5em;
`;

export const MenuCard = styled.div`
  background: #f6f6f6;
  border-radius: 5px;
  text-align: center;
  padding: 2em 1em;

  &:hover {
    background: #a4d7f3;
  }
`;

export const MenuLabel = styled.p`
  cursor: pointer;
  margin: 0;
  font-weight: 700;
  color: #324158;
  opacity: 0.8;
  text-decoration: none;

  &:hover {
    color: #324158;
  }
`;

export const NavContainer = styled(DisplayFlex)`
  justify-content: space-between;
  padding: 1em;
`;

export const LogoTitle = styled.h1`
  font-family: 'ABeeZee', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 30px;
  color: #324158;
`;
