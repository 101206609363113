import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Col } from 'react-bootstrap';

import {
  InputImageBanner,
  InputTextEditor,
  NavigationBar
} from '../../../../Components';
import {
  ButtonContainer,
  InputContainer,
  LabelForm,
  TextInput,
  TitleScreen
} from '../../../Blog/Blog.styled';
import { showNotification as showNotificationRedux } from '../../../../Redux/Actions/notification.action';
import servicesRequest from '../../../../Utils/serviceRequest.utils';
import { BURGUNDY_URL } from '../../../../Utils/serviceUrl.utils';
import ROUTE from '../../../../Constants/route.constant';
import { CancelButton, SaveButton } from '../../../Menu/Menu.styled';

const COL = 2;

const AddBurgundySubComponent = () => {
  const { id } = useParams();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      title: '',
      orderList: '',
      thumbnail: '',
      content: '',
      burgundyId: id
    }
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const onSubmit = async data => {
    try {
      const request = await servicesRequest();
      await request.post(BURGUNDY_URL + '/sub', data);
      showNotification('Burgundy successfully added');

      setTimeout(() => {
        navigate(`/${ROUTE.burgundy}`);
      }, 2000);
    } catch (error) {
      showNotification('Failed to add');
    }
  };
  return (
    <NavigationBar>
      <TitleScreen>Create New Burgundy Map </TitleScreen>
      <hr />
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Title</LabelForm>
        </Col>
        <Col>
          <Controller
            name="title"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="Title"
                error={errors?.title}
              />
            )}
          />
        </Col>
      </InputContainer>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Order List</LabelForm>
        </Col>
        <Col>
          <Controller
            name="orderList"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                type="number"
                onKeyPress={(event) => {
                  if (event.which < 48 || event.which > 57) {
                    event.preventDefault();
                  }
                }}
                value={value}
                onChange={onChange}
                placeholder="Order List"
                error={errors?.orderList}
              />
            )}
          />
        </Col>
      </InputContainer>

      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Map Image</LabelForm>
        </Col>
        <Col>
          <InputImageBanner getImageURL={url => setValue('thumbnail', url)} />
        </Col>
      </InputContainer>

      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Content</LabelForm>
        </Col>
        <Col>
          <InputTextEditor
            handleChange={content => setValue('content', content)}
          />
        </Col>
      </InputContainer>
      <ButtonContainer>
        <CancelButton onClick={() => navigate(-1)}>Cancel</CancelButton>
        <SaveButton onClick={handleSubmit(onSubmit)}>Save</SaveButton>
      </ButtonContainer>
    </NavigationBar>
  );
};

export default AddBurgundySubComponent;
