import React from 'react';
import { Checkbox, Table } from 'antd';

import PaginationComponent from '../../../Components/Pagination/Pagination.component';
import { numberWithCommas } from '../../../Utils/general.utils';
import { filter } from 'lodash/collection';

const ProductTableComponent = props => {
  const { products, selectedProduct, setSelectedProduct } = props;

  const mapping = data => {
    return data.map(item => {
      return {
        ...item,
        key: item.code
      };
    });
  };

  const handleCheckbox = code => {
    let temp = selectedProduct;

    const checkIfOnSelectedList = temp.includes(code);
    if (checkIfOnSelectedList) {
      temp = filter(selectedProduct, x => {
        return x !== code;
      });
    }

    if (!checkIfOnSelectedList) {
      temp.push(code);
    }

    return setSelectedProduct(temp);
  };

  const columns = [
    {
      title: '#',
      align: 'center',
      render: (_, record) => (
        <Checkbox
          defaultChecked={selectedProduct.includes(record?.code)}
          onClick={() => {
            handleCheckbox(record?.code);
          }}
        />
      )
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Name',
      dataIndex: 'name_en',
      key: 'name_en'
    },
    {
      title: 'Category',
      dataIndex: 'category_id',
      key: 'category_id'
    },
    {
      title: 'Price',
      align: 'center',
      dataIndex: 'aprice',
      render: (_, record) => <>${numberWithCommas(record?.aprice)}</>
    }
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        dataSource={mapping(products)}
        size="small"
        pagination={false}
      />
      <PaginationComponent offset={2} span={24} {...props} />
    </React.Fragment>
  );
};

export default ProductTableComponent;
