import React from 'react';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import DeleteBlogConfirmComponent from './DeleteBlogConfirm.component';
import ROUTE from '../../../Constants/route.constant';

const BlogTableComponent = ({ blogs, onDelete }) => {
  const navigate = useNavigate();

  const mapping = () => {
    return blogs.map(blog => {
      return {
        ...blog,
        key: blog.id
      };
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title)
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
      sorter: (a, b) => a.author.localeCompare(b.author)
    },
    {
      title: 'Readtime Estimation',
      dataIndex: 'readtime_estimation',
      key: 'readtime_estimation',
      sorter: (a, b) =>
        a.readtime_estimation.localeCompare(b.readtime_estimation)
    },
    {
      title: 'Published Date',
      dataIndex: 'createdAt',
      align: 'center',
      render: text => (
        <p className="m-0">{moment(text).format('YYYY-MM-DD')}</p>
      ),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix()
    },
    {
      title: 'Delete',
      key: 'delete',
      render: (_, record) => (
        <DeleteBlogConfirmComponent id={record.id} onDelete={onDelete} />
      )
    },
    {
      title: 'Edit Page',
      key: 'editPage',
      render: (_, record) => (
        <Button onClick={() => navigate(`/${ROUTE.blogUpdate}/${record.id}`)}>
          Edit Page
        </Button>
      )
    }
  ];

  return (
    <Table
      size="small"
      pagination={false}
      columns={columns}
      dataSource={mapping(blogs)}
    />
  );
};

export default BlogTableComponent;
