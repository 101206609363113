import React from 'react';
import { Collapse } from 'antd';

import { NavigationBar } from '../../Components';
import { TitleScreen } from '../Blog/Blog.styled';
import UpdateContactComponent from './Components/UpdateContact.component';
import ContactEnquiriesComponent from './Components/ContactEnquiries.component';

const { Panel } = Collapse;

const ContactContainer = () => {
  return (
    <NavigationBar>
      <TitleScreen>Manage Contact</TitleScreen>
      <hr />
      <Collapse bordered={false} defaultActiveKey={['2']}>
        <Panel header="Contact Info" key="1">
          <UpdateContactComponent />
        </Panel>
        <Panel header="Incoming Contact Enquiries" key="2">
          <ContactEnquiriesComponent />
        </Panel>
      </Collapse>
    </NavigationBar>
  );
};

export default ContactContainer;
