import React from 'react';
import { Button, Input } from 'antd';
import { useDispatch } from 'react-redux';

import { NavigationBar, Pagination } from '../../Components';
import servicesRequest from '../../Utils/serviceRequest.utils';
import { NEWS_URL } from '../../Utils/serviceUrl.utils';
import { TitleScreen, TitleSection } from './News.styled';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import NewsTableComponent from './Components/NewsTable.component';

const NewsContainer = () => {
  const [newsList, setNewsList] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [search, setSearch] = React.useState('');

  const pageSize = 10;

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: {
          data: { totalItems, contents }
        }
      } = await request.get(NEWS_URL, {
        params: {
          page: pages,
          size: pageSize,
          search
        }
      });

      setTotalItems(totalItems);
      setNewsList(contents);
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  const deletePost = async id => {
    try {
      const request = await servicesRequest();
      await request.delete(NEWS_URL, { data: { id } });
      await fetching();
      showNotification('News post successfully deleted');
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  const paginationProps = () => ({
    data: { total_page: totalItems, pageSize },
    onChangePage: page => {
      setPages(page - 1);
    }
  });

  React.useEffect(() => {
    fetching().catch(console.error);
  }, [pages]);

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetching().catch(console.error);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <NavigationBar>
      <TitleSection>
        <TitleScreen>Manage News</TitleScreen>
        <Button href="/news/add">Add News Post</Button>
      </TitleSection>
      <hr />
      <div className="mb-2 mt-2 w-25">
        <Input
          placeholder="Search news"
          onChange={e => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <NewsTableComponent newsList={newsList} onDelete={deletePost} />
      <Pagination {...paginationProps()} />
    </NavigationBar>
  );
};

export default NewsContainer;
