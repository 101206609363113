import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import servicesRequest from '../../../Utils/serviceRequest.utils';
import { PAYMENT_METHOD_URL } from '../../../Utils/serviceUrl.utils';
import {
  ButtonContainer,
  InputContainer,
  LabelForm,
  TextInput
} from '../../Blog/Blog.styled';
import { Col } from 'react-bootstrap';
import { CancelButton, SaveButton } from '../../Menu/Menu.styled';

const COL = 3;

const AddPaymentMethodComponent = ({
  onCancel,
  showNotification,
  fetching
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      description: ''
    }
  });

  const onSubmit = async data => {
    try {
      const request = await servicesRequest();
      await request.post(PAYMENT_METHOD_URL, data);

      await fetching();
      showNotification('Payment method successfully added');
    } catch (error) {
      showNotification('Payment method failed to updated');
    } finally {
      onCancel();
    }
  };

  return (
    <React.Fragment>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm className="pt-2">Name</LabelForm>
        </Col>
        <Col>
          <Controller
            name="name"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="Name"
                error={errors?.name}
              />
            )}
          />
        </Col>
      </InputContainer>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm className="pt-2">Description</LabelForm>
        </Col>
        <Col>
          <Controller
            name="description"
            control={control}
            rules={{
              required: false
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="Description"
                error={errors?.description}
              />
            )}
          />
        </Col>
      </InputContainer>

      <ButtonContainer className="mt-2">
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton onClick={handleSubmit(onSubmit)}>Save</SaveButton>
      </ButtonContainer>
    </React.Fragment>
  );
};
export default AddPaymentMethodComponent;
