import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'antd';

import { NavigationBar } from '../../Components';
import { TitleScreen, TitleSection } from '../Blog/Blog.styled';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import PaymeComponent from './Components/Payme.component';
import servicesRequest from '../../Utils/serviceRequest.utils';
import { PAYMENT_METHOD_URL } from '../../Utils/serviceUrl.utils';
import AddPaymentMethodComponent from './Components/AddPaymentMethod.component';
import PaymentTableComponent from './Components/PaymentTable.component';
import UpdatePaymentMethodComponent from './Components/UpdatePaymentMethod.component';
import { DisplayFlex } from '../../Assets/GlobalStyled';

const PaymentsContainer = () => {
  const [addModal, setAddModal] = React.useState(false);
  const [paymentMethods, setPaymentMethods] = React.useState([]);
  const [updateModal, setUpdateModal] = React.useState(false);
  const [dataToEdit, setDataToEdit] = React.useState(null);

  const [paymeShow, setPaymeShow] = React.useState(true);

  const dispatch = useDispatch();

  const [totalItems, setTotalItems] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const [pages, setPages] = React.useState(0);

  const pageSize = 10;

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: {
          data: { totalItems, contents }
        }
      } = await request.get(PAYMENT_METHOD_URL, {
        params: {
          page: pages,
          size: pageSize,
          search
        }
      });

      setTotalItems(totalItems);
      setPaymentMethods(contents);
    } catch (error) {
      showNotification(
        'Sorry, we are unable to get payment method list, please try again.'
      );
    }
  };

  const update = async data => {
    try {
      const request = await servicesRequest();
      await request.patch(PAYMENT_METHOD_URL, data);

      await fetching();
      showNotification('Shipment method successfully updated');
    } catch (error) {
      showNotification('Shipment method failed to updated');
    }
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, [pages]);

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const handleCloseModal = () => {
    setAddModal(false);
    setUpdateModal(false);
    setDataToEdit(null);
  };

  const setUpdateModalActive = data => {
    setUpdateModal(true);
    setDataToEdit(data);
  };

  return (
    <NavigationBar>
      <TitleSection>
        <TitleScreen>Manage Payment</TitleScreen>
        <DisplayFlex>
          <Button onClick={() => setPaymeShow(!paymeShow)}>
            {paymeShow ? 'Show' : 'Hide'} Payme Details
          </Button>
          <Button onClick={() => setAddModal(true)}>Add Payment Method</Button>
        </DisplayFlex>
      </TitleSection>
      <hr />
      <div className="my-3">
        <PaymentTableComponent
          update={update}
          payments={paymentMethods}
          setUpdateModalActive={setUpdateModalActive}
        />
      </div>

      <Modal
        width={700}
        footer={[]}
        title="Add New Shipment Method"
        open={addModal}
        onCancel={() => {
          handleCloseModal();
        }}>
        <AddPaymentMethodComponent
          showNotification={text => {
            showNotification(text);
          }}
          fetching={fetching}
          onCancel={handleCloseModal}
        />
      </Modal>
      <Modal
        width={700}
        footer={[]}
        title="Update Payment Method"
        open={updateModal}
        onCancel={() => {
          handleCloseModal();
        }}>
        <UpdatePaymentMethodComponent
          update={update}
          data={dataToEdit}
          showNotification={text => {
            showNotification(text);
          }}
          onCancel={handleCloseModal}
          fetching={fetching}
        />
      </Modal>
      <div hidden={paymeShow}>
        <PaymeComponent />
      </div>
    </NavigationBar>
  );
};

export default PaymentsContainer;
