import React from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem
} from 'cdbreact';
import { useNavigate, useLocation } from 'react-router-dom';

import ROUTE from '../../Constants/route.constant';

const NavigationBarComponent = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const goTo = route => {
    navigate(route);

    if (route === location.pathname) {
      window.location.reload();
    }
  };

  return (
    <div className="d-flex">
      <div>
        <CDBSidebar style={{ minHeight: '48em' }}>
          <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>
            WineBuff
          </CDBSidebarHeader>
          <CDBSidebarContent>
            <CDBSidebarMenu>
              <CDBSidebarMenuItem
                icon="shopping-cart"
                onClick={() => goTo('/' + ROUTE.order)}>
                Order
              </CDBSidebarMenuItem>
              <CDBSidebarMenuItem
                icon="book"
                onClick={() => goTo('/' + ROUTE.product)}>
                Product
              </CDBSidebarMenuItem>
              <CDBSidebarMenuItem
                icon="truck"
                onClick={() => goTo('/' + ROUTE.shipment)}>
                Shipment
              </CDBSidebarMenuItem>
              <CDBSidebarMenuItem
                icon="credit-card"
                onClick={() => goTo('/' + ROUTE.payments)}>
                Payments
              </CDBSidebarMenuItem>
              <CDBSidebarMenuItem
                icon="percent"
                onClick={() => goTo('/' + ROUTE.discount)}>
                Discount
              </CDBSidebarMenuItem>
              <CDBSidebarMenuItem
                icon="calendar"
                onClick={() => goTo('/' + ROUTE.event)}>
                Event
              </CDBSidebarMenuItem>
              <CDBSidebarMenuItem
                icon="bookmark"
                onClick={() => goTo('/' + ROUTE.blog)}>
                Blog
              </CDBSidebarMenuItem>
              <CDBSidebarMenuItem
                icon="book"
                onClick={() => goTo('/' + ROUTE.news)}>
                News
              </CDBSidebarMenuItem>
              <CDBSidebarMenuItem
                icon="th"
                onClick={() => goTo('/' + ROUTE.gallery)}>
                Gallery
              </CDBSidebarMenuItem>
              <CDBSidebarMenuItem
                icon="question"
                onClick={() => goTo('/' + ROUTE.faq)}>
                FAQ
              </CDBSidebarMenuItem>
              <CDBSidebarMenuItem
                icon="address-book"
                onClick={() => goTo('/' + ROUTE.contact)}>
                Contact
              </CDBSidebarMenuItem>
              <CDBSidebarMenuItem
                icon="user"
                onClick={() => goTo('/' + ROUTE.user)}>
                Member
              </CDBSidebarMenuItem>
              <CDBSidebarMenuItem
                icon="book"
                onClick={() => goTo('/' + ROUTE.content)}>
                Content
              </CDBSidebarMenuItem>
              <CDBSidebarMenuItem
                icon="map"
                onClick={() => goTo('/' + ROUTE.burgundy)}>
                Burgundy Map
              </CDBSidebarMenuItem>
              <CDBSidebarMenuItem
                icon="book"
                onClick={() => goTo('/' + ROUTE.banner)}>
                Pop Up Banner
              </CDBSidebarMenuItem>
              <CDBSidebarMenuItem icon="sign-out-alt" onClick={() => goTo('/')}>
                Logout
              </CDBSidebarMenuItem>
            </CDBSidebarMenu>
          </CDBSidebarContent>

          <CDBSidebarFooter style={{ textAlign: 'center' }}>
            <div
              className="sidebar-btn-wrapper"
              style={{ padding: '20px 5px' }}>
              {/*Sidebar Footer*/}
            </div>
          </CDBSidebarFooter>
        </CDBSidebar>
      </div>
      <div className="w-100 p-4">{children}</div>
    </div>
  );
};

export default NavigationBarComponent;
