import React from 'react';
import { Button, Input, Table } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';

import { NavigationBar, Pagination } from '../../Components';
import servicesRequest from '../../Utils/serviceRequest.utils';
import { BANNER_URL } from '../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import DeleteBlogConfirmComponent from '../Blog/Components/DeleteBlogConfirm.component';
import ROUTE from '../../Constants/route.constant';
import { TitleScreen, TitleSection } from '../Blog/Blog.styled';
import { DisplayFlex } from '../../Assets/GlobalStyled';

const CampaignTableComponent = ({ banners, onDelete }) => {
  const navigate = useNavigate();

  const mapping = () => {
    return banners.map(banner => {
      return {
        ...banner,
        key: banner.id
      };
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Action URL',
      dataIndex: 'actionUrl',
      key: 'actionUrl'
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      render: text => (
        <p className="m-0">{moment(text).format('YYYY-MM-DD')}</p>
      ),
      sorter: (a, b) =>
        moment(a.start_date).unix() - moment(b.start_date).unix()
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      render: text => (
        <p className="m-0">{moment(text).format('YYYY-MM-DD')}</p>
      ),
      sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix()
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: (_, record) => <>{record?.status ? 'Active' : 'Nonactive'}</>,
      sorter: (a, b) => a.status - b.status
    },
    {
      title: 'Action',
      render: (_, record) => (
        <DisplayFlex>
          <DeleteBlogConfirmComponent id={record.id} onDelete={onDelete} />
          <Button
            onClick={() => navigate(`/${ROUTE.bannerUpdate}/${record.id}`)}>
            Edit
          </Button>
        </DisplayFlex>
      )
    }
  ];

  return (
    <Table
      size="small"
      pagination={false}
      columns={columns}
      dataSource={mapping(banners)}
    />
  );
};

const BannerContainer = () => {
  const [banners, setBanners] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [search, setSearch] = React.useState('');

  const pageSize = 10;

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: {
          data: { totalItems, contents }
        }
      } = await request.get(BANNER_URL, {
        params: {
          page: pages,
          size: pageSize,
          search
        }
      });

      setTotalItems(totalItems);
      setBanners(contents);
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  const deleteBanner = async id => {
    try {
      const request = await servicesRequest();
      await request.delete(BANNER_URL + `/${id}`);
      await fetching();
      showNotification('Banner successfully deleted');
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, [pages]);

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetching().catch(console.error);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <NavigationBar>
      <TitleSection>
        <TitleScreen>Manage Pop Up Banner</TitleScreen>
        <Button href="/banner/add">Add Pop Up Banner</Button>
      </TitleSection>
      <hr />
      <div className="mb-2 mt-2 w-25">
        <Input
          placeholder="Search banner"
          onChange={e => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <CampaignTableComponent banners={banners} onDelete={deleteBanner} />
      <Pagination
        data={{ total_item: totalItems, itemperpage: pageSize }}
        onChangePage={page => {
          setPages(page - 1);
        }}
      />
    </NavigationBar>
  );
};

export default BannerContainer;
