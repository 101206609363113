import styled from 'styled-components';

export const Container = styled.div`
  padding: 1em;
`;

export const Logo = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h3`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #324158;
  text-align: center;
  margin-top: 2.5em;
`;

export const FormLabel = styled.h6`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #324158;
  margin: 0;
`;

export const Input = styled.input`
  background: #e0e0e0;
  opacity: 0.5;
  border-radius: 3px;
  border: none;
  padding: 0.5em;
  width: 100%;
`;
