import React from 'react';

import servicesRequest from '../../../Utils/serviceRequest.utils';
import { PRODUCT_URL } from '../../../Utils/serviceUrl.utils';
import ProductTableComponent from './ProductTable.component';
import { Button, Modal } from 'antd';

const SelectProductComponent = ({
  modalSelectProduct,
  setModalSelectProduct,
  selectedProduct,
  setSelectedProduct
}) => {
  const [products, setProducts] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [item, setItem] = React.useState(12);
  const [pagination, setPagination] = React.useState({
    page: '1',
    total_page: '1',
    total_item: '1',
    itemperpage: '20'
  });

  const fetching = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.post(PRODUCT_URL, {
        itemCode: '',
        categoryCode: '',
        itemPerPage: item,
        pageCount: page,
        filterPublish: false
      });
      setProducts(data.products);
      setPagination(data.pagination);
    } catch (error) {
      // showNotification('Something went wrong, please try again.');
    }
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, [page]);

  const props = () => ({
    products,
    page,
    setPage,
    item,
    setItem,
    pagination,
    setPagination,
    selectedProduct,
    setSelectedProduct
  });

  return (
    <Modal
      onCancel={() => setModalSelectProduct(false)}
      title={'Select Product'}
      open={modalSelectProduct}
      footer={[
        <Button key="back" onClick={() => setModalSelectProduct(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => setModalSelectProduct(false)}>
          Submit
        </Button>
      ]}>
      <ProductTableComponent {...props()} />
    </Modal>
  );
};

export default SelectProductComponent;
