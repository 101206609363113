import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import DeleteNewsConfirmComponent from './DeleteNewsConfirm.component';
import ROUTE from '../../../Constants/route.constant';

const NewsTableComponent = ({ newsList, onDelete }) => {
  const navigate = useNavigate();

  const mapping = () => {
    return newsList.map(news => {
      return {
        ...news,
        key: news.id
      };
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.title - b.title
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.author - b.author
    },
    {
      title: 'Readtime Estimation',
      dataIndex: 'readtime_estimation',
      key: 'readtime_estimation',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.readtime_estimation - b.readtime_estimation
    },
    {
      title: 'Published Date',
      dataIndex: 'createdAt',
      align: 'center',
      render: text => <p>{moment(text).format('YYYY-MM-DD')}</p>,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.createdAt - b.createdAt
    },
    {
      title: 'Delete',
      key: 'delete',
      render: (_, record) => (
        <DeleteNewsConfirmComponent id={record.id} onDelete={onDelete} />
      )
    },
    {
      title: 'Edit Page',
      key: 'editPage',
      render: (_, record) => (
        <Button onClick={() => navigate(`/${ROUTE.newsUpdate}/${record.id}`)}>
          Edit Page
        </Button>
      )
    }
  ];

  return (
    <Table
      size="small"
      pagination={false}
      columns={columns}
      dataSource={mapping(newsList)}
    />
  );
};

export default NewsTableComponent;
