import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  ButtonContainer,
  InputContainer,
  LabelForm,
  TextInput
} from '../../Blog/Blog.styled';
import { SaveButton } from '../../Menu/Menu.styled';
import servicesRequest from '../../../Utils/serviceRequest.utils';
import { PAYMENT_URL } from '../../../Utils/serviceUrl.utils';
import { decryptData, encryptData } from '../../../Utils/general.utils';
import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';

const COL = 3;

const TYPE = {
  PAYME: 'PAYME'
};

const PaymeComponent = () => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      PAYME_URL: '',
      PAYME_API_VERSION: '0.12',
      PAYME_CLIENT_ID: '',
      PAYME_CLIENT_SECRET: '',
      PAYME_SIGNING_KEY_ID: '',
      PAYME_SIGNING_KEY: ''
    }
  });

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const fetch = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: {
          data: { data }
        }
      } = await request.get(PAYMENT_URL);

      if (data !== null) {
        let { key } = data;
        key = decryptData(key);

        setValue('PAYME_URL', key?.PAYME_URL);
        setValue('PAYME_API_VERSION', key?.PAYME_API_VERSION);
        setValue('PAYME_CLIENT_ID', key?.PAYME_CLIENT_ID);
        setValue('PAYME_CLIENT_SECRET', key?.PAYME_CLIENT_SECRET);
        setValue('PAYME_SIGNING_KEY_ID', key?.PAYME_SIGNING_KEY_ID);
        setValue('PAYME_SIGNING_KEY', key?.PAYME_SIGNING_KEY);
      }
    } catch (error) {
      showNotification('Failed to get payment details');
    }
  };

  React.useEffect(() => {
    fetch().catch(console.error);
  }, []);

  const onSubmit = async data => {
    try {
      const credentials = encryptData(data);

      const request = await servicesRequest();
      await request.post(PAYMENT_URL, {
        type: TYPE.PAYME,
        key: credentials
      });

      showNotification('Payment successfully updated');
    } catch (error) {
      showNotification('Failed to add');
    }
  };

  return (
    <Row className="gy-1">
      <Col>
        <Row className="g-2">
          <Col md={12}>
            <div className="border border-dark rounded p-3">
              <h4 className="m-0 p-0">HSBC PAYME</h4>
              <hr />

              <InputContainer>
                <Col xs={COL}>
                  <LabelForm className="pt-1">PAYME URL</LabelForm>
                </Col>
                <Col>
                  <Controller
                    name="PAYME_URL"
                    control={control}
                    rules={{
                      required: true
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        value={value}
                        onChange={onChange}
                        placeholder="PAYME URL"
                        error={errors?.PAYME_URL}
                      />
                    )}
                  />
                </Col>
              </InputContainer>
              <InputContainer>
                <Col xs={COL}>
                  <LabelForm className="pt-1">PAYME API VERSION</LabelForm>
                </Col>
                <Col>
                  <Controller
                    name="PAYME_API_VERSION"
                    control={control}
                    rules={{
                      required: true
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        value={value}
                        onChange={onChange}
                        placeholder="PAYME API VERSION"
                        error={errors?.PAYME_API_VERSION}
                      />
                    )}
                  />
                </Col>
              </InputContainer>
              <InputContainer>
                <Col xs={COL}>
                  <LabelForm className="pt-1">PAYME CLIENT ID</LabelForm>
                </Col>
                <Col>
                  <Controller
                    name="PAYME_CLIENT_ID"
                    control={control}
                    rules={{
                      required: true
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        value={value}
                        onChange={onChange}
                        placeholder="PAYME CLIENT ID"
                        error={errors?.PAYME_CLIENT_ID}
                      />
                    )}
                  />
                </Col>
              </InputContainer>
              <InputContainer>
                <Col xs={COL}>
                  <LabelForm className="pt-1">PAYME CLIENT SECRET</LabelForm>
                </Col>
                <Col>
                  <Controller
                    name="PAYME_CLIENT_SECRET"
                    control={control}
                    rules={{
                      required: true
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        value={value}
                        onChange={onChange}
                        placeholder="PAYME CLIENT SECRET"
                        error={errors?.PAYME_CLIENT_SECRET}
                      />
                    )}
                  />
                </Col>
              </InputContainer>
              <InputContainer>
                <Col xs={COL}>
                  <LabelForm className="pt-1">PAYME SIGNING KEY ID</LabelForm>
                </Col>
                <Col>
                  <Controller
                    name="PAYME_SIGNING_KEY_ID"
                    control={control}
                    rules={{
                      required: true
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        value={value}
                        onChange={onChange}
                        placeholder="PAYME SIGNING KEY ID"
                        error={errors?.PAYME_SIGNING_KEY_ID}
                      />
                    )}
                  />
                </Col>
              </InputContainer>
              <InputContainer>
                <Col xs={COL}>
                  <LabelForm className="pt-1">PAYME SIGNING KEY</LabelForm>
                </Col>
                <Col>
                  <Controller
                    name="PAYME_SIGNING_KEY"
                    control={control}
                    rules={{
                      required: true
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        value={value}
                        onChange={onChange}
                        placeholder="PAYME SIGNING KEY"
                        error={errors?.PAYME_SIGNING_KEY}
                      />
                    )}
                  />
                </Col>
              </InputContainer>

              <ButtonContainer>
                <SaveButton onClick={handleSubmit(onSubmit)}>Save</SaveButton>
              </ButtonContainer>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PaymeComponent;
