import React from 'react';
import { Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { InputImageBanner, NavigationBar } from '../../../Components';
import {
  ButtonContainer,
  InputContainer,
  LabelForm,
  TextInput,
  TitleScreen
} from '../../Blog/Blog.styled';
import { CancelButton, SaveButton } from '../../Menu/Menu.styled';
import servicesRequest from '../../../Utils/serviceRequest.utils';
import { BANNER_URL } from '../../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';
import ROUTE from '../../../Constants/route.constant';

const COL = 2;

const AddBannerComponent = () => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      actionUrl: '',
      image: '',
      start_date: '',
      end_date: '',
      status: true
    }
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const onSubmit = async data => {
    try {
      const request = await servicesRequest();
      await request.post(BANNER_URL, data);
      showNotification('Banner successfully added');

      setTimeout(() => {
        navigate(`/${ROUTE.banner}`);
      }, 2000);
    } catch (error) {
      showNotification('Failed to add');
    }
  };

  return (
    <NavigationBar>
      <TitleScreen>Create New Banner </TitleScreen>
      <hr />
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Name</LabelForm>
        </Col>
        <Col>
          <Controller
            name="name"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="Banner Name"
                error={errors?.name}
              />
            )}
          />
        </Col>
      </InputContainer>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Action URL</LabelForm>
        </Col>
        <Col>
          <Controller
            name="actionUrl"
            control={control}
            rules={{
              required: false
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="Action Url"
                error={errors?.actionUrl}
              />
            )}
          />
        </Col>
      </InputContainer>

      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Start Date</LabelForm>
        </Col>
        <Col>
          <Controller
            name="start_date"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                type="date"
                value={value}
                onChange={onChange}
                placeholder="Start Date"
                error={errors?.start_date}
              />
            )}
          />
        </Col>
      </InputContainer>

      <InputContainer>
        <Col xs={COL}>
          <LabelForm>End Date</LabelForm>
        </Col>
        <Col>
          <Controller
            name="end_date"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                type="date"
                value={value}
                onChange={onChange}
                placeholder="End Date"
                error={errors?.end_date}
              />
            )}
          />
        </Col>
      </InputContainer>

      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Published to Online Store</LabelForm>
        </Col>
        <Col>
          <Controller
            name="status"
            control={control}
            render={({ field: { onChange, value } }) => (
              <input
                type="checkbox"
                checked={value}
                onChange={event => {
                  onChange(event.target.checked);
                }}
              />
            )}
          />
        </Col>
      </InputContainer>

      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Banner Image</LabelForm>
        </Col>
        <Col>
          <InputImageBanner getImageURL={url => setValue('image', url)} />
        </Col>
      </InputContainer>

      <ButtonContainer>
        <CancelButton onClick={() => navigate(-1)}>Cancel</CancelButton>
        <SaveButton onClick={handleSubmit(onSubmit)}>Save</SaveButton>
      </ButtonContainer>
    </NavigationBar>
  );
};

export default AddBannerComponent;
