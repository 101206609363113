import React from 'react';
import { Button, Modal, Space, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import ROUTE from '../../../Constants/route.constant';
import moment from 'moment';
import DeleteBlogConfirmComponent from '../../Blog/Components/DeleteBlogConfirm.component';
import { exportToCSV } from '../../../Utils/general.utils';

const EventTableComponent = ({ events, onDelete }) => {
  const [event, setEvent] = React.useState('');

  const navigate = useNavigate();

  const _renderTableModal = () => {
    const { dynamicForm, event_orders: eventOrders } = event;
    const newColumn = dynamicForm?.map(item => {
      return {
        title: item?.name,
        dataIndex: item?.name,
        key: item?.name
      };
    });

    const data = eventOrders?.map(item => {
      return {
        key: item?.id,
        ...item?.guest
      };
    });

    return (
      <Table
        pagination={false}
        columns={newColumn}
        dataSource={data}
        size="small"
      />
    );
  };

  const closeModal = () => {
    setEvent('');
  };

  const mapping = () => {
    return events.map(event => {
      return {
        ...event,
        key: event.id
      };
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title)
    },
    {
      title: 'Event Date',
      dataIndex: 'event_date',
      align: 'center',
      key: 'event_date',
      sorter: (a, b) =>
        moment(a.event_date).unix() - moment(b.event_date).unix()
    },
    {
      title: 'Event Time',
      dataIndex: 'event_time',
      align: 'center',
      key: 'event_time',
      sorter: (a, b) =>
        moment(a.event_time, 'HH:mm:ss').unix() -
        moment(b.event_time, 'HH:mm:ss').unix()
    },
    {
      title: 'Published Date',
      dataIndex: 'createdAt',
      align: 'center',
      render: text => <p>{moment(text).format('YYYY-MM-DD')}</p>,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix()
    },
    {
      title: 'Registered User',
      align: 'center',
      render: (_, record) => <>{record?.event_orders?.length}</>,
      sorter: (a, b) =>
        (a.event_orders?.length || 0) - (b.event_orders?.length || 0)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: (_, record) => <>{record?.status ? 'Active' : 'Nonactive'}</>,
      sorter: (a, b) => a.status - b.status
    },
    {
      title: 'Active',
      dataIndex: 'status',
      align: 'center',
      render: (_, record) => <>{record?.isActive ? 'Active' : 'Nonactive'}</>,
      sorter: (a, b) => a.isActive - b.isActive
    },
    {
      title: 'Action',
      align: 'center',
      render: (_, record) => (
        <>
          <Space>
            <Button
              type="default"
              onClick={() => navigate(`/${ROUTE.event}/update/${record.id}`)}>
              Update Event
            </Button>
            <Button
              disabled={record?.event_orders?.length === 0}
              type="primary"
              onClick={() => {
                setEvent(record);
              }}>
              See Registered User
            </Button>
            <Button
              disabled={record?.event_orders?.length === 0}
              type="default"
              onClick={() => {
                const reformatGuest = record.event_orders.map(item => {
                  return {
                    Name: item.guest.Name,
                    Email: item.guest.Email,
                    Phone: item.guest.Phone
                  };
                });
                exportToCSV(reformatGuest, record.title);
              }}>
              Download
            </Button>
            <DeleteBlogConfirmComponent
              id={record.id}
              onDelete={() => {
                onDelete(record?.id);
              }}
            />
          </Space>
        </>
      )
    }
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        dataSource={mapping()}
        size="small"
        pagination={false}
      />
      <Modal
        width={1000}
        footer={[]}
        title={event?.title}
        open={event !== ''}
        onOk={closeModal}
        onCancel={closeModal}>
        {event && _renderTableModal()}
      </Modal>
    </React.Fragment>
  );
};

export default EventTableComponent;
