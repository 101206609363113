import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { find } from 'lodash';
import { Button } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';
import servicesRequest from '../../../Utils/serviceRequest.utils';
import { CONTENT_URL } from '../../../Utils/serviceUrl.utils';
import {
  ButtonContainer,
  InputContainer,
  LabelForm,
  TextInput
} from '../../Blog/Blog.styled';
import { InputImageBanner, InputTextEditor } from '../../../Components';
import { AddUpdateContainer } from '../Content.styled';
import { CancelButton, SaveButton } from '../../Menu/Menu.styled';

const COL = 2;

const AddUpdateContentComponent = ({ menu, onCancel, contents }) => {
  const onlyThumbnail = menu?.onlyThumbnail || false;

  const [banner, setBanner] = useState(
    onlyThumbnail ? [{ uri: '', redirect_url: '' }] : ''
  );

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      title: '',
      title_ch: '',
      thumbnail: onlyThumbnail ? banner : '',
      content: '',
      content_ch: '',
      width: '',
      height: ''
    }
  });

  useEffect(() => {
    const findContent = find(contents, { type: menu.value });

    if (findContent !== undefined) {
      let { thumbnail, title, title_ch, content, content_ch, width, height } =
        findContent;

      thumbnail = onlyThumbnail ? JSON.parse(thumbnail) : thumbnail;

      setValue('title', title || '');
      setValue('title_ch', title_ch || '');
      setValue('thumbnail', thumbnail);
      setValue('content', content);
      setValue('content_ch', content_ch || '');
      setValue('width', width);
      setValue('height', height);

      setBanner(thumbnail);
    } else {
      let defaultDynamicThumbnail = [{ uri: '' }];

      setValue('title', menu.label);
      setValue('title_ch', menu.label);
      setValue('thumbnail', onlyThumbnail ? defaultDynamicThumbnail : '');
      setValue('content', '');
      setValue('content_ch', '');
      setValue('width', '');
      setValue('height', '');

      setBanner(defaultDynamicThumbnail);
    }
    forceUpdate();
  }, [menu]);

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const onSubmit = async data => {
    let { thumbnail } = data;
    thumbnail = onlyThumbnail ? JSON.stringify(thumbnail) : thumbnail;
    try {
      const payload = {
        ...data,
        thumbnail,
        type: menu.value
      };

      const request = await servicesRequest();
      await request.post(CONTENT_URL, payload);

      showNotification('Content successfully updated');

      setTimeout(() => {
        onCancel();
      }, 2000);
    } catch (error) {
      showNotification('Failed to updated');
    }
  };

  const handleUpdateDynamicBannerImage = (uri, index) => {
    let tempBanner = banner;
    tempBanner[index].uri = uri;
    setBanner(tempBanner);
    setValue('thumbnail', tempBanner);
  };

  const handleUpdateDynamicBannerUrl = (redirectUrl, index) => {
    let tempBanner = banner;
    tempBanner[index].redirect_url = redirectUrl;
    setBanner(tempBanner);
    setValue('thumbnail', tempBanner);
  };

  const addDynamicBanner = () => {
    let tempBanner = banner;
    tempBanner.push({ uri: '', redirect_url: '' });
    setBanner(tempBanner);
    setValue('thumbnail', tempBanner);
    forceUpdate();
  };

  const deleteDynamicBanner = index => {
    let tempBanner = banner;
    tempBanner.splice(index, 1);
    setBanner(tempBanner);
    setValue('thumbnail', tempBanner);
    forceUpdate();
  };

  const renderDynamicThumbnail = () => {
    if (Array.isArray(banner) && onlyThumbnail) {
      return (
        <>
          {banner?.map((item, index) => (
            <>
              <InputContainer key={index}>
                <Col xs={COL}>
                  <LabelForm>Cover</LabelForm>
                  <Button
                    icon={<DeleteFilled />}
                    onClick={() => deleteDynamicBanner(index)}
                  />
                </Col>
                <Col>
                  <Controller
                    name="thumbnail"
                    control={control}
                    render={() => (
                      <InputImageBanner
                        getImageURL={uri => {
                          handleUpdateDynamicBannerImage(uri, index);
                        }}
                        value={item.uri}
                      />
                    )}
                  />
                </Col>
              </InputContainer>
              <InputContainer>
                <Col xs={COL}>
                  <LabelForm>Redirect URL</LabelForm>
                </Col>
                <Col>
                  <Controller
                    name="thumbnail"
                    control={control}
                    rules={{
                      required: false
                    }}
                    render={() => (
                      <TextInput
                        value={item?.redirect_url || ''}
                        onChange={e =>
                          handleUpdateDynamicBannerUrl(e.target.value, index)
                        }
                        placeholder="https://winebuff.com.hk/product"
                      />
                    )}
                  />
                </Col>
              </InputContainer>
            </>
          ))}
          <Button onClick={addDynamicBanner}>Add more banner</Button>
        </>
      );
    }
  };

  return (
    <AddUpdateContainer>
      {!onlyThumbnail && (
        <>
          <InputContainer>
            <Col xs={COL}>
              <LabelForm>Title</LabelForm>
            </Col>
            <Col>
              <Controller
                name="title"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    value={value}
                    onChange={onChange}
                    placeholder="Title"
                    error={errors?.title}
                  />
                )}
              />
            </Col>
          </InputContainer>
          <InputContainer>
            <Col xs={COL}>
              <LabelForm>Title (Chinese)</LabelForm>
            </Col>
            <Col>
              <Controller
                name="title_ch"
                control={control}
                rules={{
                  required: false
                }}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    value={value}
                    onChange={onChange}
                    placeholder="Title (Chinese)"
                    error={errors?.title_ch}
                  />
                )}
              />
            </Col>
          </InputContainer>
        </>
      )}

      {/* dynamic thumbnail for banner */}
      {renderDynamicThumbnail()}

      {/* non-dynamic thumbnail for banner */}
      {!onlyThumbnail && (
        <InputContainer>
          <Col xs={COL}>
            <LabelForm>Cover</LabelForm>
          </Col>
          <Col>
            <Controller
              name="thumbnail"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputImageBanner getImageURL={onChange} value={value} />
              )}
            />
            <Row className="mt-2">
              <Col>
                <LabelForm>Width</LabelForm>
                <Controller
                  name="width"
                  control={control}
                  rules={{
                    required: false
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      placeholder="Width"
                      error={errors?.width}
                    />
                  )}
                />
              </Col>
              <Col>
                <LabelForm>Height</LabelForm>
                <Controller
                  name="height"
                  control={control}
                  rules={{
                    required: false
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      placeholder="Height"
                      error={errors?.height}
                    />
                  )}
                />
              </Col>
            </Row>
          </Col>
        </InputContainer>
      )}

      {!onlyThumbnail && (
        <>
          <InputContainer>
            <Col xs={COL}>
              <LabelForm>Content</LabelForm>
            </Col>
            <Col>
              <Controller
                name="content"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputTextEditor value={value} handleChange={onChange} />
                )}
              />
            </Col>
          </InputContainer>
          <InputContainer>
            <Col xs={COL}>
              <LabelForm>Content (Chinese)</LabelForm>
            </Col>
            <Col>
              <Controller
                name="content_ch"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputTextEditor value={value} handleChange={onChange} />
                )}
              />
            </Col>
          </InputContainer>
        </>
      )}

      <ButtonContainer>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton onClick={handleSubmit(onSubmit)}>Save</SaveButton>
      </ButtonContainer>
    </AddUpdateContainer>
  );
};

export default AddUpdateContentComponent;
