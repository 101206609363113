import React from 'react';
import JoditEditor from 'jodit-react';

import { UPLOAD_IMAGE_URL } from '../../Utils/serviceUrl.utils';

const InputTextEditor = ({ handleChange, value = '' }) => {
  const editor = React.useRef(null);

  const buttons = [
    'undo',
    'redo',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    '|',
    'superscript',
    'subscript',
    '|',
    'align',
    '|',
    'ul',
    'ol',
    'outdent',
    'indent',
    '|',
    'font',
    'fontsize',
    'paragraph',
    '|',
    'link',
    'table',
    '|',
    'hr',
    'fullsize',
    'image'
  ];

  const config = React.useMemo(
    () => ({
      height: 350,
      toolbarButtonSize: 'medium',
      toolbarAdaptive: false,
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: true,
      askBeforePasteFromWord: true,
      buttons: buttons,
      uploader: {
        url: UPLOAD_IMAGE_URL,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('wb-token')}`
        },
        withCredentials: false,
        pathVariableName: 'path',
        format: 'json',
        method: 'POST',
        prepareData: function (formData) {
          const file = formData.getAll('files[0]')[0];
          formData.delete('files[0]');
          formData.append('image', file);
          formData.delete('path');
          formData.delete('source');
          return formData;
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join('\n');
        },
        process: function (resp) {
          return {
            files: [resp.data.substring(51)],
            baseurl: resp.data.substring(0, 51),
            message: '',
            error: '',
            path: resp.data
          };
        },
        defaultHandlerSuccess: function (data) {
          let i,
            field = 'files';
          if (data[field] && data[field].length) {
            for (i = 0; i < data[field].length; i += 1) {
              this.selection.insertImage(data.baseurl + data[field][i]);
            }
          }
        }
      }
    }),
    []
  );

  const link = {
    processVideoLink: false
  };

  return (
    <div>
      <JoditEditor
        ref={editor}
        value={value}
        link={link}
        config={config}
        onChange={handleChange}
      />
    </div>
  );
};

export default InputTextEditor;
