import styled from 'styled-components';
import { DisplayFlex } from '../../Assets/GlobalStyled';
import { Row, Col } from 'react-bootstrap';

export const InputImageContainer = styled.div`
  width: 100%;
  height: auto;

  background: #e0e0e0;
  opacity: 0.5;

  border: 2px dashed rgba(50, 65, 88, 0.5);
  border-radius: 5px;
  text-align: center;
  padding: 20px;
  cursor: pointer
`;
export const InnerTitleDescription = styled.span`
  font-family: 'ABeeZee';
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: rgba(50, 65, 88, 0.5);
`;