import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';

import DeleteGalleryConfirmComponent from './DeleteGalleryConfirm.component';
import ROUTE from '../../../Constants/route.constant';
import moment from 'moment';

const GalleryTableComponent = ({ gallerys, onDelete }) => {
  const navigate = useNavigate();

  const mapping = () => {
    return gallerys.map(gallery => {
      return {
        ...gallery,
        key: gallery.id
      };
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.title - b.title
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.author - b.author
    },
    {
      title: 'Published Date',
      dataIndex: 'createdAt',
      align: 'center',
      render: text => <p>{moment(text).format('YYYY-MM-DD')}</p>,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.createdAt - b.createdAt
    },
    {
      title: 'Delete',
      key: 'delete',
      render: (_, record) => (
        <DeleteGalleryConfirmComponent id={record.id} onDelete={onDelete} />
      )
    },
    {
      title: 'Edit Page',
      key: 'editPage',
      render: (_, record) => (
        <Button onClick={() => navigate(`/${ROUTE.galleryUpdate}/${record.id}`)}>
          Edit Page
        </Button>
      )
    }
  ];

  return (
    <Table
      size="small"
      pagination={false}
      columns={columns}
      dataSource={mapping(gallerys)}
    />
  );
};

export default GalleryTableComponent;
