import React from 'react';
import { Button, Table } from 'antd';

const ShipmentTableComponent = ({ shipments, setUpdateModalActive }) => {
  const mapping = () => {
    return shipments.map(shipment => {
      return {
        ...shipment,
        key: shipment.id
      };
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.title - b.title
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Fee',
      dataIndex: 'fee',
      key: 'fee',
      align: 'right',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.fee - b.fee
    },
    {
      title: 'Free Threshold',
      dataIndex: 'freeThreshold',
      key: 'freeThreshold',
      align: 'right',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.freeThreshold - b.freeThreshold
    },
    {
      title: 'Address Required',
      align: 'center',
      render: (_, record) => (
        <>{record?.isAddressRequired === true ? 'Yes' : 'No'}</>
      )
    },
    {
      title: 'Action',
      key: 'editPage',
      align: 'center',
      render: (_, record) => (
        <Button onClick={() => setUpdateModalActive(record)}>Update</Button>
      )
    }
  ];

  return (
    <Table
      size="small"
      pagination={false}
      columns={columns}
      dataSource={mapping()}
    />
  );
};

export default ShipmentTableComponent;
