import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';

import {
  ButtonContainer,
  InputContainer,
  LabelForm,
  TextInput
} from '../../Blog/Blog.styled';
import { InputTextEditor } from '../../../Components';
import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';
import servicesRequest from '../../../Utils/serviceRequest.utils';
import { CONTACT_URL } from '../../../Utils/serviceUrl.utils';
import { CancelButton, SaveButton } from '../../Menu/Menu.styled';
import BranchModalComponent from './BranchModal.component';

const COL = 2;

const UpdateContactComponent = () => {
  const [contactId, setContactId] = React.useState(new Date().getTime());
  const [branch, setBranch] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [dataToEdit, setDataToEdit] = React.useState(null);

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const handleSaveBranch = data => {
    let tempBranch = branch;
    if (tempBranch.length === 0) {
      tempBranch.push(data);
    } else {
      if (data.index !== undefined) {
        tempBranch[data?.index].id = data?.id;
        tempBranch[data?.index].storeName = data?.storeName;
        tempBranch[data?.index].address = data?.address;
        tempBranch[data?.index].storeContact = data?.storeContact;
        tempBranch[data?.index].workHour = data?.workHour;
        tempBranch[data?.index].maps = data?.maps;
      } else {
        tempBranch.push(data);
      }
    }

    setBranch(tempBranch);
    setModal(false);
    setDataToEdit(null);
  };

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: {
          data: {
            id,
            phone,
            email,
            address,
            address_map,
            content,
            content_ch,
            branch: branchInfo
          }
        }
      } = await request.get(CONTACT_URL);

      if (branchInfo) {
        setBranch(JSON.parse(branchInfo));
      }

      if (id !== undefined) {
        setContactId(id);
      }
      setValue('phone', phone);
      setValue('email', email);
      setValue('address', address);
      setValue('address_map', address_map);
      setValue('content', content);
      setValue('content_ch', content_ch);
    } catch (error) {
      showNotification(
        'Sorry, we unable to update your contact info. Please try again'
      );
    }
  };

  const onSubmit = async data => {
    try {
      const request = await servicesRequest();
      await request.patch(CONTACT_URL, {
        id: contactId,
        branch: JSON.stringify(branch),
        ...data
      });
      showNotification('Contact successfully updated');
    } catch (error) {
      showNotification('Failed to update');
    }
  };

  const onDeleteBranch = index => {
    let tempBranchToDelete = branch;

    tempBranchToDelete.splice(index, 1);

    setBranch([...tempBranchToDelete]);
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, []);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      phone: '',
      email: '',
      address: '',
      address_map: '',
      content: '',
      content_ch: ''
    }
  });

  return (
    <div>
      {/*<InputContainer>*/}
      {/*  <Col xs={COL}>*/}
      {/*    <LabelForm>Phone</LabelForm>*/}
      {/*  </Col>*/}
      {/*  <Col>*/}
      {/*    <Controller*/}
      {/*      name="phone"*/}
      {/*      control={control}*/}
      {/*      rules={{*/}
      {/*        required: true*/}
      {/*      }}*/}
      {/*      render={({ field: { onChange, value } }) => (*/}
      {/*        <TextInput*/}
      {/*          value={value}*/}
      {/*          onChange={onChange}*/}
      {/*          placeholder="Phone"*/}
      {/*          error={errors?.title}*/}
      {/*        />*/}
      {/*      )}*/}
      {/*    />*/}
      {/*  </Col>*/}
      {/*</InputContainer>*/}
      {/*<InputContainer>*/}
      {/*  <Col xs={COL}>*/}
      {/*    <LabelForm>Email</LabelForm>*/}
      {/*  </Col>*/}
      {/*  <Col>*/}
      {/*    <Controller*/}
      {/*      name="email"*/}
      {/*      control={control}*/}
      {/*      rules={{*/}
      {/*        required: true*/}
      {/*      }}*/}
      {/*      render={({ field: { onChange, value } }) => (*/}
      {/*        <TextInput*/}
      {/*          value={value}*/}
      {/*          onChange={onChange}*/}
      {/*          placeholder="Email"*/}
      {/*          error={errors?.title}*/}
      {/*        />*/}
      {/*      )}*/}
      {/*    />*/}
      {/*  </Col>*/}
      {/*</InputContainer>*/}
      {/*<InputContainer>*/}
      {/*  <Col xs={COL}>*/}
      {/*    <LabelForm>Address</LabelForm>*/}
      {/*  </Col>*/}
      {/*  <Col>*/}
      {/*    <Controller*/}
      {/*      name="address"*/}
      {/*      control={control}*/}
      {/*      rules={{*/}
      {/*        required: true*/}
      {/*      }}*/}
      {/*      render={({ field: { onChange, value } }) => (*/}
      {/*        <TextInput*/}
      {/*          value={value}*/}
      {/*          onChange={onChange}*/}
      {/*          placeholder="Address"*/}
      {/*          error={errors?.title}*/}
      {/*        />*/}
      {/*      )}*/}
      {/*    />*/}
      {/*  </Col>*/}
      {/*</InputContainer>*/}
      {/*<InputContainer>*/}
      {/*  <Col xs={COL}>*/}
      {/*    <LabelForm>Address Map</LabelForm>*/}
      {/*  </Col>*/}
      {/*  <Col>*/}
      {/*    <Controller*/}
      {/*      name="address_map"*/}
      {/*      control={control}*/}
      {/*      render={({ field: { onChange, value } }) => (*/}
      {/*        <TextInput*/}
      {/*          value={value}*/}
      {/*          onChange={onChange}*/}
      {/*          placeholder="Address Map"*/}
      {/*          error={errors?.address_map}*/}
      {/*        />*/}
      {/*      )}*/}
      {/*    />*/}
      {/*  </Col>*/}
      {/*</InputContainer>*/}
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Content</LabelForm>
        </Col>
        <Col>
          <Controller
            name="content"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputTextEditor value={value} handleChange={onChange} />
            )}
          />
        </Col>
      </InputContainer>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Content (Chinese)</LabelForm>
        </Col>
        <Col>
          <Controller
            name="content_ch"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputTextEditor value={value} handleChange={onChange} />
            )}
          />
        </Col>
      </InputContainer>
      <Button
        onClick={() => {
          setModal(true);
        }}>
        Add Branch Info
      </Button>

      {branch?.length > 0 && (
        <div className="mt-4">
          <h6>Branch Info</h6>
          <Row className="g-1">
            {branch.map((item, index) => (
              <Col
                md={4}
                key={index}
                className="card rounded border border-light p-2">
                <p>{item?.storeName}</p>
                <Row>
                  <Col>
                    <Button
                      className="w-100"
                      onClick={() => {
                        setModal(true);
                        setDataToEdit({ item, index });
                      }}>
                      Update
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className="w-100"
                      danger
                      onClick={() => {
                        onDeleteBranch(index);
                      }}>
                      Delete
                    </Button>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </div>
      )}

      <ButtonContainer>
        <CancelButton>Cancel</CancelButton>
        <SaveButton onClick={handleSubmit(onSubmit)}>Save</SaveButton>
      </ButtonContainer>

      <BranchModalComponent
        isOpen={modal}
        dataToEdit={dataToEdit}
        onCancel={() => {
          setModal(false);
        }}
        onOk={handleSaveBranch}
      />
    </div>
  );
};

export default UpdateContactComponent;
