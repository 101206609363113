import styled from 'styled-components';
import { DisplayFlex } from '../../Assets/GlobalStyled';
import { Row, Col } from 'react-bootstrap';

export const Container = styled.div`
  display: flex;
  background: #ececec;
`;

export const RightSide = styled.div`
  padding: 2em;
`;

export const Title = styled.h3`
  font-family: 'ABeeZee', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  color: #324158;
`;

export const Subtitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: rgba(50, 65, 88, 0.5);
`;

export const CreateTitle = styled.h4`
  font-weight: 700;
  font-size: 18px;
  color: #324158;
`;

export const CreateButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  border: 2px solid #248271;
  border-radius: 8px;
  color: #248271;
  padding: 0.5em 0.5em;
`;

export const ModalTitle = styled.h4`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #324158;
`;

export const ModalLabel = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: #324158;
`;

export const Input = styled.input`
  background: #e0e0e0;
  opacity: 0.5;
  border-radius: 3px;
  border: none;
  width: 100%;
  padding: 0.5em;
`;

export const Disclaimer = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #324158;
  opacity: 0.8;
`;

export const ButtonContainer = styled(DisplayFlex)`
  justify-content: center;
  gap: 0.5em;
  margin-top: 1.5em;
`;

export const CancelButton = styled.button`
  border: 2px solid #3f2e8d;
  border-radius: 5px;
  background: #fff;
  color: #3f2e8d;
  padding: 0.3em 1em;
  width: 7em;
`;
export const SaveButton = styled.button`
  border: 2px solid #3f2e8d;
  background: #3f2e8d;
  border-radius: 5px;
  padding: 0.3em 1em;
  color: #fff;
  width: 7em;
`;
export const TableHeader = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: rgba(50, 65, 88, 0.5);
  margin: 0;
`;

export const THName = styled.th`
  width: 20em;
`;

export const TableContent = styled.p`
  font-weight: 700;
  font-size: 16px;
  margin: 0;
  color: #324158;
`;

export const TableContentDelete = styled(TableContent)`
  color: rgba(50, 65, 88, 0.5);
`;

export const InputContainer = styled(Row)`
  padding-top: 20px;
`;

export const InputImageContainer = styled.div`
  width: 100%;
  height: auto;

  background: #e0e0e0;
  opacity: 0.5;

  border: 2px dashed rgba(50, 65, 88, 0.5);
  border-radius: 5px;
  text-align: center;
  padding: 20px;
  cursor: pointer
`;
export const InnerTitleDescription = styled.span`
  font-family: 'ABeeZee';
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: rgba(50, 65, 88, 0.5);
`;

export const InputDeletedImageContainer = styled.div`
  background: rgba(50, 65, 88, 0.1);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LeftSideImage = styled.div`
  width: 60px;
  height: 60px;

  background: #c4c4c4;
  border-radius: 5px;
`;

export const RightSideImage = styled.div`
  padding: 5px;
`;

export const InputSquareImageContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: left;
`;

export const LeftSquareSideImage = styled.div`
  width: 127px;
  height: 127px;

  /* Gray 5 */

  background: #e0e0e0;
  opacity: 0.5;
  /* Secondary text */

  border: 2px dashed rgba(50, 65, 88, 0.5);
  border-radius: 5px;
`;

export const RightSquareSideImage = styled.div`
  width: 127px;
  height: 127px;

  /* Gray 5 */

  background: #e0e0e0;
  opacity: 0.5;
  /* Secondary text */

  border: 2px dashed rgba(50, 65, 88, 0.5);
  border-radius: 5px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CancelButton2 = styled.button`
  background: blue;
`;

export const SaveButton2 = styled.button`
  padding-left: 20px;
`;

export const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: center
`;
