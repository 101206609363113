export const BASE_URL = process.env.REACT_APP_API_URL;

// export const SIGN_UP_URL = `${BASE_URL}admin/sign-up`;
export const SIGN_IN_URL = `${BASE_URL}admin/sign-in`;
export const BLOG_URL = `${BASE_URL}blog`;
export const BANNER_URL = `${BASE_URL}banner`;
export const NEWS_URL = `${BASE_URL}news`;
export const GALLERY_URL = `${BASE_URL}gallery`;
export const CONTACT_URL = `${BASE_URL}contact`;
export const CONTACT_ENQUIRIES_URL = `${CONTACT_URL}/enquiries`;
export const UPLOAD_IMAGE_URL = `${BASE_URL}upload-image`;
export const FAQ_URL = `${BASE_URL}faq`;
export const DISCOUNT_URL = `${BASE_URL}discount`;
export const PRODUCT_URL = `${BASE_URL}product`;
export const USER_URL = `${BASE_URL}user`;
export const EVENT_URL = `${BASE_URL}event`;
export const PURCHASE_URL = `${BASE_URL}purchase/admin`;
export const CONTENT_URL = `${BASE_URL}content`;
export const BURGUNDY_URL = `${BASE_URL}burgundy`;
export const SHIPMENT_URL = `${BASE_URL}shipment`;
export const PAYMENT_URL = `${BASE_URL}payment`;
export const PAYMENT_METHOD_URL = `${PAYMENT_URL}/payment-method`;
