import React from 'react';

import { InputImageBanner } from '../../../Components';

const UpdateProductImageComponent = ({ getImageURL, value }) => {
  return (
    <div>
      <InputImageBanner getImageURL={getImageURL} value={value} />
    </div>
  );
};

export default UpdateProductImageComponent;
