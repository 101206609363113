import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Col } from 'react-bootstrap';

import {
  ButtonContainer,
  InputContainer,
  LabelForm,
  TextInput
} from '../../Blog/Blog.styled';
import { CancelButton, SaveButton } from '../../Menu/Menu.styled';

const COL = 3;

const UpdatePaymentMethodComponent = ({ onCancel, data, update }) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      paymentMethodId: ''
    }
  });

  React.useEffect(() => {
    setValue('name', data?.name);
    setValue('description', data?.description);
    setValue('paymentMethodId', data?.id);
  }, [data]);

  const onSubmit = async data => {
    try {
      await update(data);
    } catch (error) {
    } finally {
      onCancel();
    }
  };
  return (
    <React.Fragment>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm className="pt-2">Title</LabelForm>
        </Col>
        <Col>
          <Controller
            name="name"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="Name"
                error={errors?.name}
              />
            )}
          />
        </Col>
      </InputContainer>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm className="pt-2">Description</LabelForm>
        </Col>
        <Col>
          <Controller
            name="description"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="Description"
                error={errors?.description}
              />
            )}
          />
        </Col>
      </InputContainer>

      <ButtonContainer className="mt-4">
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton onClick={handleSubmit(onSubmit)}>Save</SaveButton>
      </ButtonContainer>
    </React.Fragment>
  );
};

export default UpdatePaymentMethodComponent;
