import { createBrowserRouter } from 'react-router-dom';

import {
  AddBannerScreen,
  AddBlogScreen,
  AddBurgundyScreen,
  AddBurgundySubScreen,
  AddDiscountScreen,
  AddEventScreen,
  AddGalleryScreen,
  AddNewsScreen,
  BannerScreen,
  BlogScreen,
  BurgundyScreen,
  ContactScreen,
  ContentScreen,
  DetailOrderScreen,
  DiscountScreen,
  EventScreen,
  FAQScreen,
  GalleryScreen,
  Home,
  Login,
  NewsScreen,
  NotFoundScreen,
  OrderScreen,
  PaymentsScreen,
  ProductScreen,
  ShipmentScreen,
  UpdateBannerScreen,
  UpdateBlogScreen,
  UpdateBurgundyScreen,
  UpdateBurgundySubScreen,
  UpdateDiscountScreen,
  UpdateEventScreen,
  UpdateGalleryScreen,
  UpdateNewsScreen,
  UserScreen
} from '../Screens';
import Protected from '../Components/Protected';

import ROUTE from '../Constants/route.constant';

export const router = createBrowserRouter([
  {
    path: ROUTE.root,
    element: <Login />
  },
  {
    path: ROUTE.home,
    element: (
      <Protected>
        <Home />
      </Protected>
    )
  },
  {
    path: ROUTE.blog,
    element: (
      <Protected>
        <BlogScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.blogAdd,
    element: (
      <Protected>
        <AddBlogScreen />
      </Protected>
    )
  },
  {
    path: `${ROUTE.blogUpdate}/:id`,
    element: (
      <Protected>
        <UpdateBlogScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.news,
    element: (
      <Protected>
        <NewsScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.newsAdd,
    element: (
      <Protected>
        <AddNewsScreen />
      </Protected>
    )
  },
  {
    path: `${ROUTE.newsUpdate}/:id`,
    element: (
      <Protected>
        <UpdateNewsScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.gallery,
    element: (
      <Protected>
        <GalleryScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.galleryAdd,
    element: (
      <Protected>
        <AddGalleryScreen />
      </Protected>
    )
  },
  {
    path: `${ROUTE.galleryUpdate}/:id`,
    element: (
      <Protected>
        <UpdateGalleryScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.contact,
    element: (
      <Protected>
        <ContactScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.faq,
    element: (
      <Protected>
        <FAQScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.discount,
    element: (
      <Protected>
        <DiscountScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.discountAdd,
    element: (
      <Protected>
        <AddDiscountScreen />
      </Protected>
    )
  },
  {
    path: `${ROUTE.discountUpdate}/:id`,
    element: (
      <Protected>
        <UpdateDiscountScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.product,
    element: (
      <Protected>
        <ProductScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.user,
    element: (
      <Protected>
        <UserScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.event,
    element: (
      <Protected>
        <EventScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.eventAdd,
    element: (
      <Protected>
        <AddEventScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.eventUpdate,
    element: (
      <Protected>
        <UpdateEventScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.order,
    element: (
      <Protected>
        <OrderScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.order + '/:data',
    element: (
      <Protected>
        <DetailOrderScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.content,
    element: (
      <Protected>
        <ContentScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.shipment,
    element: (
      <Protected>
        <ShipmentScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.payments,
    element: (
      <Protected>
        <PaymentsScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.burgundy,
    element: (
      <Protected>
        <BurgundyScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.burgundyAdd,
    element: (
      <Protected>
        <AddBurgundyScreen />
      </Protected>
    )
  },
  {
    path: `${ROUTE.burgundyUpdate}/:id`,
    element: (
      <Protected>
        <UpdateBurgundyScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.burgundySubAdd + '/:id',
    element: (
      <Protected>
        <AddBurgundySubScreen />
      </Protected>
    )
  },
  {
    path: `${ROUTE.burgundySubUpdate}/:id`,
    element: (
      <Protected>
        <UpdateBurgundySubScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.banner,
    element: (
      <Protected>
        <BannerScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.bannerAdd,
    element: (
      <Protected>
        <AddBannerScreen />
      </Protected>
    )
  },
  {
    path: ROUTE.bannerUpdate + `/:id`,
    element: (
      <Protected>
        <UpdateBannerScreen />
      </Protected>
    )
  },
  {
    path: '*',
    element: <NotFoundScreen />
  }
]);
