import React from 'react';
import { Button, Select, Table } from 'antd';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';

const UserTableComponent = ({ users, members, handleUpdateStatus }) => {
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [memberType, setMemberType] = React.useState('');

  React.useEffect(() => {
    setMemberType(members[0]?.label);
  }, [members]);

  const mapping = () => {
    return users.map(user => {
      return {
        ...user,
        key: user.id
      };
    });
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id.localeCompare(b.id)
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email)
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a.phone.localeCompare(b.phone)
    },
    {
      title: 'Registered',
      dataIndex: 'createdAt',
      render: (_, record) => (
        <>{moment(record?.createdAt).format('YYYY-MM-DD')}</>
      ),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix()
    },
    {
      title: 'Membership',
      align: 'center',
      dataIndex: 'membershipType',
      render: (_, record) => <>{record?.membershipType || 'MEMBER'}</>,
      sorter: (a, b) => a.membershipType.localeCompare(b.membershipType)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, record) => (
        <>{record?.status === true ? 'Active' : 'Nonactive'}</>
      ),
      sorter: (a, b) => a.status - b.status
    },
    {
      title: 'Action',
      align: 'center',
      render: (_, record) => (
        <>
          <Button
            onClick={() => {
              setSelectedUser(record);
            }}>
            Update
          </Button>
        </>
      )
    }
  ];

  const handleChange = value => {
    setMemberType(value);
  };

  const _renderSelect = () => {
    return (
      <Select
        className="mt-3"
        defaultValue={memberType}
        style={{
          width: '100%'
        }}
        onChange={handleChange}
        options={members}
      />
    );
  };

  return (
    <React.Fragment>
      <Row className="g-5">
        <Col md={selectedUser === null ? 12 : 9}>
          <Table columns={columns} dataSource={mapping()} size="small" />
        </Col>
        {selectedUser !== null && (
          <Col className="">
            <div className="border border-light rounded p-2">
              <h5>Update User Member</h5>
              <hr />
              {`[${selectedUser?.id}] ${selectedUser?.name}`} {_renderSelect()}
              <Button
                className="mt-2 text-center"
                onClick={() => {
                  handleUpdateStatus(selectedUser.id, memberType);
                  setSelectedUser(null);
                }}>
                Update Membership
              </Button>
            </div>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

export default UserTableComponent;
