import React from 'react';
import { useDispatch } from 'react-redux';
import { Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { find } from 'lodash';

import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';
import servicesRequest from '../../../Utils/serviceRequest.utils';
import { CONTENT_URL } from '../../../Utils/serviceUrl.utils';
import {
  ButtonContainer,
  InputContainer,
  LabelForm,
  TextInput
} from '../../Blog/Blog.styled';
import { CancelButton, SaveButton } from '../../Menu/Menu.styled';
import { AddUpdateContainer } from '../Content.styled';

const COL = 2;

const AddUpdateSocialMediaComponent = ({ menu, contents, onCancel }) => {
  const dispatch = useDispatch();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      facebook: '',
      instagram: '',
      twitter: '',
      youtube: ''
    }
  });

  React.useEffect(() => {
    const findContent = find(contents, { type: menu.value });
    if (findContent !== undefined) {
      const { content } = findContent;

      const { facebook, instagram, twitter, youtube } = JSON.parse(content);

      setValue('facebook', facebook);
      setValue('instagram', instagram);
      setValue('twitter', twitter);
      setValue('youtube', youtube);
    }
  }, [menu]);

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const onSubmit = async data => {
    try {
      const payload = {
        thumbnail: '',
        title: menu.value,
        type: menu.value,
        content: JSON.stringify(data)
      };

      const request = await servicesRequest();
      await request.post(CONTENT_URL, payload);

      showNotification('Content successfully updated');

      setTimeout(() => {
        onCancel();
      }, 2000);
    } catch (error) {
      showNotification('Failed to updated');
    }
  };

  return (
    <AddUpdateContainer>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Facebook</LabelForm>
        </Col>
        <Col>
          <Controller
            name="facebook"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="Facebook"
                error={errors?.facebook}
              />
            )}
          />
        </Col>
      </InputContainer>

      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Instagram</LabelForm>
        </Col>
        <Col>
          <Controller
            name="instagram"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="Instagram"
                error={errors?.instagram}
              />
            )}
          />
        </Col>
      </InputContainer>
      <ButtonContainer>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton onClick={handleSubmit(onSubmit)}>Save</SaveButton>
      </ButtonContainer>
    </AddUpdateContainer>
  );
};

export default AddUpdateSocialMediaComponent;
