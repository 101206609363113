import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';

import ROUTE from '../../../Constants/route.constant';
import { DISCOUNT_METHOD } from '../../../Constants';

const DiscountTableComponent = ({ discounts }) => {
  const navigate = useNavigate();

  const mapping = () => {
    return discounts.map(discount => {
      return {
        ...discount,
        key: discount.id
      };
    });
  };

  const columns = [
    {
      title: 'Method',
      dataIndex: 'method',
      render: (_, record) => (
        <>{record?.method === DISCOUNT_METHOD.AUTO ? 'Auto' : 'Code'}</>
      )
    },
    {
      title: 'Code',
      dataIndex: 'discount_code',
      key: 'discount_code'
    },
    {
      title: 'Apply To',
      dataIndex: 'apply_to',
      key: 'apply_to'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Value',
      dataIndex: 'value',
      align: 'center',
      key: 'value'
    },
    {
      title: 'Validation',
      dataIndex: 'validation_period',
      render: (_, record) => (
        <>{record?.validation_period === true ? 'Active' : 'Nonactive'}</>
      )
    },
    {
      title: 'Start',
      dataIndex: 'validation_period_start',
      key: 'validation_period_start'
    },
    {
      title: 'End',
      dataIndex: 'validation_period_end',
      key: 'validation_period_end'
    },
    {
      title: 'Limit',
      dataIndex: 'limitation',
      render: (_, record) => (
        <>{record?.limitation === true ? 'Active' : 'Nonactive'}</>
      )
    },
    {
      title: 'Limit Value',
      dataIndex: 'limitation_value',
      align: 'center',
      key: 'limitation_value'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: (_, record) => (
        <Button danger={!record.status}>
          {record.status === true ? 'ACTIVE' : 'FALSE'}
        </Button>
      )
    },
    {
      title: 'Edit',
      align: 'center',
      key: 'update',
      render: (_, record) => (
        <Button
          onClick={() => navigate(`/${ROUTE.discountUpdate}/${record.id}`)}>
          Edit
        </Button>
      )
    }
  ];

  return <Table columns={columns} dataSource={mapping()} size="small" />;
};

export default DiscountTableComponent;
