import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, Table } from 'antd';
import { Col } from 'react-bootstrap';

import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';
import servicesRequest from '../../../Utils/serviceRequest.utils';
import { FAQ_URL } from '../../../Utils/serviceUrl.utils';
import {
  InputContainer,
  LabelForm,
  TextInput,
  TitleScreen
} from '../../Blog/Blog.styled';
import { InputTextEditor } from '../../../Components';

const FAQTableComponent = ({ faq, fetching }) => {
  const [dataToEdit, setDataToEdit] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const dispatch = useDispatch();

  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question'
    },
    {
      title: 'Answer',
      dataIndex: 'answer',
      key: 'answer',
      render: text => <div dangerouslySetInnerHTML={{ __html: text }}></div>,

    },
    {
      title: 'Action',
      key: 'editPage',
      render: (_, record) => (
        <Button
          onClick={() => {
            setDataToEdit(record);
            showModal();
          }}>
          Edit
        </Button>
      )
    }
  ];

  const mapping = () => {
    return faq.map(item => {
      return {
        ...item,
        key: item.id
      };
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleUpdate = async () => {
    try {
      const request = await servicesRequest();
      await request.patch(FAQ_URL, {
        id: dataToEdit?.id,
        ...dataToEdit
      });
      showNotification('FAQ successfully updated');
      await fetching();
    } catch (error) {
      showNotification('Failed to update');
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleDelete = async () => {
    try {
      const id = dataToEdit.id
      const request = await servicesRequest();
      await request.delete(FAQ_URL, { data: { id } });
      await fetching();
      showNotification('Faq successfully deleted');
    } catch (error) {
      showNotification('Failed to delete');
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  return (
    <React.Fragment>
      <Table columns={columns} dataSource={mapping()} size="small" />
      <Modal
        open={isModalOpen}
        onOk={handleUpdate}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button
            onClick={handleDelete}
          >
            Delete
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleUpdate}>
            Update
          </Button>
        ]}>
        <TitleScreen>Update FAQ</TitleScreen>
        <hr />

        <InputContainer className="justify-content-md-center">
          <Col xs={3}>
            <LabelForm>Question</LabelForm>
          </Col>
          <Col>
            <TextInput
              onChange={event =>
                setDataToEdit({
                  ...dataToEdit,
                  question: event.target.value
                })
              }
              value={dataToEdit?.question}
              placeholder="Question"
            />
          </Col>
        </InputContainer>

        <InputContainer className="justify-content-md-center">
          <Col xs={3}>
            <LabelForm>Answer</LabelForm>
          </Col>
          <Col>
            <InputTextEditor
              value={dataToEdit?.answer}
              handleChange={content => setDataToEdit({
                ...dataToEdit,
                answer: content
              })
              }
            />
          </Col>
        </InputContainer>
      </Modal>
    </React.Fragment>
  );
};

export default FAQTableComponent;
