import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { NavigationBar } from '../../Components';
import { TitleScreen, TitleSection } from '../Blog/Blog.styled';
import { Card } from './Content.styled';
import AddUpdateContentComponent from './Components/AddUpdateContent.component';
import servicesRequest from '../../Utils/serviceRequest.utils';
import { CONTENT_URL } from '../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import AddUpdateSocialMediaComponent from './Components/AddUpdateSocialMedia.component';

const menu = [
  {
    label: 'About Us',
    value: 'ABOUT_US'
  },
  {
    label: 'Delivery Terms',
    value: 'RETURN_POLICY'
  },
  {
    label: 'Terms & Conditions',
    value: 'TERM_OF_USE'
  },
  {
    label: 'Privacy Policy',
    value: 'PRIVACY_POLICY'
  },
  {
    label: 'EPR Compliance',
    value: 'EPR_COMPLIANCE'
  },
  {
    label: 'Social Media',
    value: 'SOCIAL_MEDIA'
  },
  {
    label: 'Home Banner',
    value: 'HOME_BANNER',
    onlyThumbnail: true
  },
  {
    label: 'Product Banner',
    value: 'PRODUCT_BANNER',
    onlyThumbnail: true
  },
  {
    label: 'Event Banner',
    value: 'EVENT_BANNER',
    onlyThumbnail: true
  },
  {
    label: 'Blog Banner',
    value: 'BLOG_BANNER',
    onlyThumbnail: true
  },
  {
    label: 'Wine Cellar',
    value: 'WINE_CELLAR'
  }
];

const ContentContainer = () => {
  const [selectedMenu, setSelectedMenu] = React.useState(null);
  const [contents, setContents] = React.useState([]);

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const fetching = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.get(CONTENT_URL);

      setContents(data);
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, [selectedMenu]);

  return (
    <NavigationBar>
      <TitleSection>
        <TitleScreen>
          Manage{' '}
          {selectedMenu === null ? 'Content' : <b>{selectedMenu.label}</b>}
        </TitleScreen>
      </TitleSection>
      <hr />
      <Row className="gy-1">
        <Col md={selectedMenu === null ? 8 : 4}>
          <Row className="g-2">
            {menu.map((item, index) => (
              <Col md={4} key={index} onClick={() => setSelectedMenu(item)}>
                <Card>{item.label}</Card>
              </Col>
            ))}
          </Row>
        </Col>
        <Col>
          {selectedMenu !== null && selectedMenu !== menu[5] && (
            <AddUpdateContentComponent
              menu={selectedMenu}
              contents={contents}
              onCancel={() => setSelectedMenu(null)}
            />
          )}

          {selectedMenu !== null && selectedMenu === menu[5] && (
            <AddUpdateSocialMediaComponent
              menu={selectedMenu}
              contents={contents}
              onCancel={() => setSelectedMenu(null)}
            />
          )}
        </Col>
      </Row>
    </NavigationBar>
  );
};

export default ContentContainer;
