import styled from 'styled-components';

export const Card = styled.div`
  padding: 1em;
  border: 1px solid #111827;
  border-radius: 8px;
  background-color: #111827;
  color: white;
  font-weight: bold;
  cursor: pointer;
  height: 100%;
`;

export const AddUpdateContainer = styled.div`
  border: 0.5px solid #111827;
  border-radius: 8px;
  padding: 1em;
`;
