import React from 'react';
import { Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  InputImageBanner,
  InputTextEditor,
  NavigationBar
} from '../../../../Components';
import {
  ButtonContainer,
  InputContainer,
  LabelForm,
  TextInput,
  TitleScreen
} from '../../../Blog/Blog.styled';
import { CancelButton, SaveButton } from '../../../Menu/Menu.styled';
import servicesRequest from '../../../../Utils/serviceRequest.utils';
import { BURGUNDY_URL } from '../../../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../../../Redux/Actions/notification.action';
import ROUTE from '../../../../Constants/route.constant';

const COL = 2;

const UpdateBurgundySubComponent = () => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      title: '',
      orderList: '',
      thumbnail: '',
      content: ''
    }
  });

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetch = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: {
          data: { content, thumbnail, title, orderList }
        }
      } = await request.get(`${BURGUNDY_URL}/sub/detail/${id}`);

      setValue('title', title);
      setValue('orderList', orderList);
      setValue('thumbnail', thumbnail);
      setValue('content', content);
    } catch (error) {
      showNotification('Something went wrong');
    }
  };

  React.useEffect(() => {
    fetch().catch(console.error);
  }, []);

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const onSubmit = async data => {
    try {
      const request = await servicesRequest();
      await request.patch(BURGUNDY_URL + '/sub', {
        id,
        ...data
      });
      showNotification('Burgundy map successfully updated');

      setTimeout(() => {
        navigate(`/${ROUTE.burgundy}`);
      }, 2000);
    } catch (error) {
      showNotification('Failed to update');
    }
  };

  return (
    <NavigationBar>
      <TitleScreen>Update Content </TitleScreen>
      <hr />
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Title</LabelForm>
        </Col>
        <Col>
          <Controller
            name="title"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="Title"
                error={errors?.title}
              />
            )}
          />
        </Col>
      </InputContainer>
      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Order List</LabelForm>
        </Col>
        <Col>
          <Controller
            name="orderList"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                type="number"
                onKeyPress={(event) => {
                  if (event.which < 48 || event.which > 57) {
                    event.preventDefault();
                  }
                }}
                value={value}
                onChange={onChange}
                placeholder="Order List"
                error={errors?.orderList}
              />
            )}
          />
        </Col>
      </InputContainer>

      <InputContainer>
        <Col xs={COL}>
          <LabelForm>MAP</LabelForm>
        </Col>
        <Col>
          <Controller
            name="thumbnail"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputImageBanner getImageURL={onChange} value={value} />
            )}
          />
        </Col>
      </InputContainer>

      <InputContainer>
        <Col xs={COL}>
          <LabelForm>Content</LabelForm>
        </Col>
        <Col>
          <Controller
            name="content"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputTextEditor value={value} handleChange={onChange} />
            )}
          />
        </Col>
      </InputContainer>

      <ButtonContainer>
        <CancelButton onClick={() => navigate(-1)}>Cancel</CancelButton>
        <SaveButton onClick={handleSubmit(onSubmit)}>Save</SaveButton>
      </ButtonContainer>
    </NavigationBar>
  );
};

export default UpdateBurgundySubComponent;
