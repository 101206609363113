import { applyMiddleware, combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';

import notificationReducer from './Reducers/notification.reducer';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: []
};

const rootReducer = combineReducers({
  notificationReducer: persistReducer(persistConfig, notificationReducer)
});

export const store = createStore(rootReducer, applyMiddleware(thunk));

export const persistor = persistStore(store);
