import React from 'react';
import { Button, Modal } from 'antd';
import { Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { NavigationBar, InputTextEditor } from '../../Components';
import {
  InputContainer,
  LabelForm,
  TextInput,
  TitleScreen,
  TitleSection
} from '../Blog/Blog.styled';
import FAQTableComponent from './Components/FAQTable.component';
import servicesRequest from '../../Utils/serviceRequest.utils';
import { FAQ_URL } from '../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';

const FAQContainer = () => {
  const [data, setData] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [faq, setFaq] = React.useState([]);

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(FAQ_URL);

      setFaq(data);
    } catch (error) {
      showNotification('Something went wrong, please try again.');
    }
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, []);

  const handleSubmit = async () => {
    try {
      const request = await servicesRequest();
      await request.post(FAQ_URL, data);
      showNotification('FAQ successfully added');
      await fetching();
    } catch (error) {
      showNotification('Failed to add');
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const props = {
    faq,
    fetching
  };

  return (
    <NavigationBar>
      <TitleSection>
        <TitleScreen>Manage FAQ</TitleScreen>
        <Button onClick={() => setIsModalOpen(true)}>Add New FAQ</Button>
      </TitleSection>
      <hr />
      <FAQTableComponent {...props} />

      <Modal
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Add
          </Button>
        ]}>
        <TitleScreen>Add FAQ</TitleScreen>
        <hr />

        <InputContainer className="justify-content-md-center">
          <Col xs={3}>
            <LabelForm>Question</LabelForm>
          </Col>
          <Col>
            <TextInput
              onChange={event =>
                setData({
                  ...data,
                  question: event.target.value
                })
              }
              value={data?.question}
              placeholder="Question"
            />
          </Col>
        </InputContainer>

        <InputContainer className="justify-content-md-center">
          <Col xs={3}>
            <LabelForm>Answer</LabelForm>
          </Col>
          <Col>
            <InputTextEditor
              handleChange={content => setData({
                ...data,
                answer: content
              })
              }
            />
          </Col>
        </InputContainer>
      </Modal>
    </NavigationBar>
  );
};

export default FAQContainer;
